import styles from '../styles/styles.module.css'
import { Nav, Navbar, Container, Image, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import imgLogo from '../images/logo_black.png'
import useUser from '../hooks/useUser'
import { PageRoutes } from '../constants'

export const NavigationBar = () => {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const userData = useUser(user?.email)

  useEffect(() => {
    if (userData) {
      if (!userData?.isProfileComplete) {
        navigate('/registerProfile')
      }
    }
  }, [userData, navigate])

  if (loading) {
    return (
      <div className="loading-component">
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        className={styles.sideMenuBarBackgroundColor + ' pt-4 px-3'}
      >
        <Container fluid className="mx-0">
          <Navbar.Brand className="navbar-brand pe-3">
            <Nav.Link as={Link} to="/">
              <Image src={imgLogo} fluid width={200} />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="mb-2"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto justify-content-end">
              {/*{!user && (
                  <Button className={styles.buttonOutline + " px-4 ms-4"} size="sm">
                      <Nav.Link as={Link} to="/register">
                      <span className={styles.buttonLabel}>Register</span>
                      </Nav.Link>
                  </Button>
              )}*/}
              {!user && (
                <Button className={styles.buttonOutline + ' px-3 py-0 me-3'} size="sm">
                  <Nav.Link as={Link} to={PageRoutes.LOGIN}>
                    <span
                      className={styles.buttonLabel}
                      style={{ color: '#3f46d6' }}
                    >
                      Login
                    </span>
                  </Nav.Link>
                </Button>
              )}
              {!user && (
                <Button className={styles.button + ' px-3 py-0'} size="sm">
                  <Nav.Link as={Link} to={PageRoutes.DEMO}>
                    <span
                      className={styles.buttonLabel}
                      style={{ color: '#FFFFFF' }}
                    >
                      Request Demo
                    </span>
                  </Nav.Link>
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
