import { Form, InputGroup } from 'react-bootstrap'
import styles from '../styles/styles.module.css'

const LabeledInputField = ({
  controlId,
  label,
  type,
  value,
  onChange,
  required = false,
  leftAccessory,
  rightAccessory
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={styles.detailItemLabel}>
        {label}
        {required && (
          <span className="ms-1" style={{ color: 'red' }}>
            *
          </span>
        )}
      </Form.Label>
      <InputGroup>
        {leftAccessory && <InputGroup.Text>{leftAccessory}</InputGroup.Text>}
        <Form.Control
          type={type}
          onChange={onChange}
          value={value}
          required={required}
        />
        {rightAccessory && <InputGroup.Text>{rightAccessory}</InputGroup.Text>}
      </InputGroup>
    </Form.Group>
  )
}

export default LabeledInputField
