import { NewsPost } from '../types'

export const convertWordpressPostToNewsPost = (post: any): NewsPost => {
  return {
    id: post.id,
    title: post.title.rendered,
    contentHtml: post.content.rendered,
    imageUrl: post.jetpack_featured_media_url,
    date: new Date(post.date),
    slug: post.slug
  }
}

export const stripHtml = (html: string): string =>
  html.replace(/<\/?[^>]+(>|$)/g, '')
