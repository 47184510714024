import styles from '../styles/styles.module.css'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Nav,
  Button,
  Stack,
  Badge,
  Row,
  Spinner,
  OverlayTrigger,
  Popover
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { auth } from '../firebase'
import { getFile } from '../services/fileStorageServices'
import { getSupplierQuotes } from '../services/supplierServices'
import * as constants from '../constants'
import { PageRoutes } from '../constants'
import useUser from '../hooks/useUser'
import { formatDate, isPastTimestamp } from '../utils/timestamp'

function ProjectCard(props) {
  const data = props.data
  const buyer = useUser(data?.createdBy)
  const [buyerLogoImage, setBuyerLogoImage] = useState()
  const [buyerLogoImageLoading, setBuyerLogoImageLoading] = useState(true)
  const [quoteSubmitted, setQuoteSubmitted] = useState(false)
  const [quoteDraftSaved, setQuoteDraftSaved] = useState(false)

  useEffect(() => {
    async function buyerLogoFetchData() {
      if (
        buyer &&
        buyer?.logoFileRefData &&
        buyer?.logoFileRefData?.length > 0
      ) {
        try {
          const response = await getFile(buyer?.logoFileRefData[0].blobName)
          const base64String = btoa(
            new Uint8Array(response.data[0].data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          )
          setBuyerLogoImage(base64String)
        } catch (error) {
          console.log(error)
        }
      }
    }
    buyerLogoFetchData()
  }, [buyer])

  useEffect(() => {
    if (buyerLogoImage) {
      setBuyerLogoImageLoading(false)
    }
  }, [buyerLogoImage])

  useEffect(() => {
    async function supplierQuoteFetchData() {
      try {
        const response = await getSupplierQuotes(
          auth?.currentUser?.email,
          data?.id
        )
        if (response && response?.length > 0) {
          if (response[0]?.status === constants.STATUS_QUOTE_DRAFT) {
            setQuoteDraftSaved(true)
          } else {
            setQuoteSubmitted(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    supplierQuoteFetchData()
  }, [])

  const isAcceptingQuotesPastDueDate = useMemo(
    () =>
      data.status === constants.STATUS_PROJECT_ACCEPTING_QUOTES &&
      data.quoteAcceptanceDueDate &&
      isPastTimestamp(data.quoteAcceptanceDueDate),
    [data]
  )

  const renderQuoteStatueBadge = accessory => {
    if (!data) return undefined

    const badgeData = quoteSubmitted
      ? { text: 'Quoted', bg: 'primary', textColor: 'white' }
      : quoteDraftSaved
        ? { text: 'Quote drafted', bg: 'warning', textColor: 'dark' }
        : isAcceptingQuotesPastDueDate
          ? { text: 'Overdue', bg: 'danger', textColor: 'white' }
          : { text: data.status, bg: 'success', textColor: 'white' }

    return (
      <Badge
        style={{ cursor: 'pointer' }}
        bg={badgeData.bg}
        text={badgeData.textColor}
      >
        {badgeData.text} {accessory}
      </Badge>
    )
  }

  return (
    <Card>
      {buyerLogoImageLoading ? (
        <Card.Header>
          <Row className="mx-3 my-4">
            Loading ...
            <Spinner
              animation="border"
              variant="secondary"
              role="status"
              className="mx-3"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Card.Header>
      ) : (
        <Card.Img
          variant="top"
          src={`data:image/png;base64,${buyerLogoImage}`}
          height={100}
          style={{ objectFit: 'contain' }}
          className="pt-3 px-3"
        />
      )}
      <ListGroup className="list-group-flush border-0">
        <ListGroup.Item className="py-3">
          <div className={styles.prjCardTitle}>{data?.name}</div>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Brand</div>
            <div className={styles.prjCardItemValue}>{data?.brand}</div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Base type</div>
            <div className={styles.prjCardItemValue}>{data?.base?.type}</div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Base Material</div>
            <div className={styles.prjCardItemValue}>
              {data?.base?.material}
            </div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Required delivery</div>
            <div className={styles.prjCardItemValue}>
              {formatDate(data.deliveryDate)}
            </div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>
              Initial order quantity
            </div>
            <div className={styles.prjCardItemValue}>
              {data?.initOrderQuantity}
            </div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>
              Has contract manufacturer
            </div>
            <div className={styles.prjCardItemValue}>
              {data?.hasContractManufacturer
                ? data?.hasContractManufacturer
                : 'N/A'}
            </div>
          </DetailItem>
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Quote acceptance due</div>
            <div className={styles.prjCardItemValue}>
              {data.quoteAcceptanceDueDate
                ? formatDate(data.quoteAcceptanceDueDate)
                : 'N/A'}
            </div>
          </DetailItem>
        </ListGroup.Item>
        <ListGroup.Item className="py-2">
          <DetailItem>
            <div className={styles.prjCardItemLabel}>Status</div>
            <h5 className="pt-2">
              {isAcceptingQuotesPastDueDate ? (
                <TooltipQuotesPastDueDate projectId={data.id}>
                  {renderQuoteStatueBadge('ⓘ')}
                </TooltipQuotesPastDueDate>
              ) : (
                renderQuoteStatueBadge()
              )}
            </h5>
          </DetailItem>
        </ListGroup.Item>
      </ListGroup>
      <Card.Body className="pt-0">
        <Stack direction="vertical" gap={2}>
          <Button className={styles.buttonOutline + ' mb-2'} size="sm">
            <Nav.Link
              as={Link}
              to={`${PageRoutes.PROJECT_DETAILS}/${data.id}?allowQuoteSubmit=true`}
            >
              View details
            </Nav.Link>
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  )
}

const DetailItem = ({ children }) => {
  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="pb-1"
      style={{ justifyContent: 'space-between' }}
    >
      {children}
    </Stack>
  )
}

const TooltipQuotesPastDueDate = ({ projectId, children }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      delay={{ show: 250, hide: 1000 }}
      overlay={
        <Popover
          id={`popover-quote-info-${projectId}`}
          style={{ minWidth: '400px' }}
        >
          <Popover.Body>
            <div>
              Past-due project quotes can still be submitted until the status
              changes to "Supplier Selected."
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default ProjectCard
