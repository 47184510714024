import styles from '../styles/styles.module.css'
import { Container, Row, Col, Image, Stack, Card } from 'react-bootstrap'
import imgHero from '../images/home_hero.png'
import { Footer } from '../components/Footer'
import imgCompany1 from '../images/suppliers/acospack-logo.png'
import imgCompany2 from '../images/suppliers/cleta-logo.png'
import imgCompany3 from '../images/suppliers/nvi-logo.png'
import imgCompany4 from '../images/suppliers/presto-logo.png'
import imgCompany5 from '../images/suppliers/samhwa-logo-white.png'
import imgCompany6 from '../images/suppliers/shp-logo.svg'
import imgBulletPoint from '../images/icons/bulletPoint.png'
import imgOurService from '../images/home_our_service.png'
import imgAboutUs from '../images/home_about_us.png'
import imgSustainability from '../images/home_sustainability.png'
import imgQualityAssurance from '../images/home_qa.png'
import imgOpen from '../images/home_open.png'
import imgEfficient from '../images/home_efficient.png'
import imgSecure from '../images/home_secure.png'
import imgBrand from '../images/home_brand.png'
import imgSupplier from '../images/home_supplier.png'

export const Home = () => {
  return (
    <>
      <Container
        fluid={true}
        className={
          'px-0 py-0 ' + styles.backgroundColor + ' ' + styles.textColor
        }
      >
        <Row className="px-4 py-4">
          <Image src={imgHero} rounded />
        </Row>
        <br className="mx-4 my-4" />
        <Row className="justify-content-md-center px-4 py-5">
          <Col md={5} className="py-3">
            <Stack direction="vertical" gap={3}>
              <div className="d-flex align-items-center">
                <Image
                  src={imgBulletPoint}
                  className="pe-3"
                  style={{ height: '15px' }}
                />
                <span className={styles.homeSectionTitle}>OUR PLATFORM</span>
              </div>
              <div>
                <span className={styles.homeSectionHeadline}>Mission</span>
              </div>
            </Stack>
          </Col>
          <Col md={5} className="py-3">
            <Stack direction="vertical" gap={3}>
              <div className={styles.homeSectionContent}>
                Our platform is a two-sided online marketplace that connects
                Brands with Suppliers. We facilitate supplier discovery,
                negotiations between suppliers and buyers, and payments. It
                operates as a “reverse marketplace”: Brands post jobs, and
                Suppliers bid on them.
              </div>
              <div className={styles.homeSectionContent}>
                Our mission is to revolutionize the cosmetic packaging supply
                chain by creating a transparent, agile, and high-performing
                ecosystem that seamlessly connects Suppliers and Brands.
              </div>
            </Stack>
          </Col>
        </Row>
        <hr className="mx-4 my-4" />
        <Row className="justify-content-md-center px-4 py-5">
          <Row className="justify-content-md-center mb-4">
            <Col md={5} className="py-3">
              <Stack direction="vertical" gap={3}>
                <div className="d-flex align-items-center">
                  <Image
                    src={imgBulletPoint}
                    className="pe-3"
                    style={{ height: '15px' }}
                  />
                  <span className={styles.homeSectionTitle}>OUR SERVICE</span>
                </div>
                <div>
                  <span className={styles.homeSectionHeadline}>
                    What we provide
                  </span>
                </div>
              </Stack>
            </Col>
            <Col md={5} className="py-3">
              <Stack direction="vertical" gap={3}>
                <div className={styles.homeSectionContent}>
                  Our platform connects brands with pre-vetted suppliers,
                  offering a seamless way to post jobs and receive competitive
                  quotes. Key benefits include:
                </div>
              </Stack>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={5} className="mb-4">
              <div>
                <Image rounded src={imgOurService} width={'80%'} />
              </div>
            </Col>
            <Col md={5}>
              <Stack direction="vertical" gap={3}>
                <div className="mb-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Quality Assurance
                  </div>
                  <div className={styles.homeSectionContent}>
                    We ensure high standards with pre-shipment audits.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Sustainable Practices
                  </div>
                  <div className={styles.homeSectionContent}>
                    We promote eco-friendly materials and practices.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Transparent Pricing
                  </div>
                  <div className={styles.homeSectionContent}>
                    Receive detailed, itemized quotes for easy comparison.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Efficient Collaboration with Supplers
                  </div>
                  <div className={styles.homeSectionContent}>
                    Seamless component design adjustments and revisions in a
                    streamlined process.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Risk Assessment
                  </div>
                  <div className={styles.homeSectionContent}>
                    We offer risk assessments through expert consultations.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Secure Transactions
                  </div>
                  <div className={styles.homeSectionContent}>
                    Use our escrow service and dispute resolution for peace of
                    mind.
                  </div>
                </div>
              </Stack>
            </Col>
          </Row>
        </Row>
        <hr className="mx-4 my-4" />
        <Row className="justify-content-md-center px-4 py-5">
          <Col md={10} className="py-3">
            <Stack direction="vertical" gap={3}>
              <div className="d-flex align-items-center">
                <Image
                  src={imgBulletPoint}
                  className="pe-3"
                  style={{ height: '15px' }}
                />
                <span className={styles.homeSectionTitle}>ABOUT US</span>
              </div>
              <div>
                <span className={styles.homeSectionHeadline}>Who we are</span>
              </div>
            </Stack>
            <Row className="pt-5">
              <Image rounded src={imgAboutUs} width={'100%'} />
            </Row>
            <Row className="pt-5">
              <div className={styles.homeSectionContent}>
                We are a team of industry experts and technology professionals
                dedicated to transforming the cosmetic packaging supply chain.
                Committed to providing an open, efficient, and secure
                marketplace, we strive to offer a platform that benefits all
                participants, facilitating collaboration and growth in the
                industry.
              </div>
            </Row>
            <Row className="pt-5">
              <Col className="text-center">
                <Stack
                  direction="vertical"
                  gap={2}
                  className="py-5"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="pb-3">
                    <Image src={imgOpen} />
                  </div>
                  <div className={styles.homeSectionBoxTitle + ' pb-3'}>
                    Open
                  </div>
                  <div className={styles.homeSectionContent}>Environment</div>
                </Stack>
              </Col>
              <Col className="text-center">
                <Stack
                  direction="vertical"
                  gap={2}
                  className="py-5"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="pb-3">
                    <Image src={imgEfficient} />
                  </div>
                  <div className={styles.homeSectionBoxTitle + ' pb-3'}>
                    Efficent
                  </div>
                  <div className={styles.homeSectionContent}>Collaboration</div>
                </Stack>
              </Col>
              <Col className="text-center">
                <Stack
                  direction="vertical"
                  gap={2}
                  className="py-5"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="pb-3">
                    <Image src={imgSecure} />{' '}
                  </div>
                  <div className={styles.homeSectionBoxTitle + ' pb-3'}>
                    Secure
                  </div>
                  <div className={styles.homeSectionContent}>Marketplace</div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
        <br className="mx-4 my-4" />
        <Row
          className={
            'justify-content-md-center mx-0 px-4 py-5 ' +
            styles.secondaryBackgroundColor
          }
        >
          <Col md={10} className="py-5">
            <Stack direction="vertical" gap={3}>
              <div className="d-flex align-items-center">
                <Image
                  src={imgBulletPoint}
                  className="pe-3"
                  style={{ height: '15px' }}
                />
                <span className={styles.homeSectionTitle}>VALUES</span>
              </div>
              <div>
                <span className={styles.homeSectionHeadline}>
                  Why choose us
                </span>
              </div>
            </Stack>
            <Row className="pt-5">
              <div className={styles.homeSectionTitle + ' mb-2'}>
                Simplifying Your Sourcing and Negotiation
              </div>
              <div className={styles.homeSectionContent}>
                We offer an end-to-end solution for all your sourcing needs,
                from initial quotes to final delivery. Our platform stands out
                by emphasizing:
              </div>
            </Row>
            <Row className="pt-5 mb-4">
              <Col md={6}>
                <Card style={{ backgroundColor: '#EDF3F8' }} className="me-3">
                  <Card.Body className="text-center py-5">
                    <div className={styles.homeCardTitle + ' pb-5'}>
                      Quality Assurance
                    </div>
                    <div className="pb-4">
                      <Image src={imgQualityAssurance} rounded width={'90%'} />
                    </div>
                    <div className={styles.homeCardContent + ' py-3 px-4'}>
                      We work with pre-vetted suppliers and provide pre-shipment
                      audits to maintain high standards.
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ backgroundColor: '#EDF3F8' }} className="me-3">
                  <Card.Body className="text-center py-5">
                    <div className={styles.homeCardTitle + ' pb-5'}>
                      Sustainability Focus
                    </div>
                    <div className="pb-4">
                      <Image src={imgSustainability} rounded width={'90%'} />
                    </div>
                    <div className={styles.homeCardContent + ' py-3 px-4'}>
                      We encourage the use of eco-friendly materials, aligning
                      with the growing demand for sustainable products.
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <br className="mx-4 my-4" />
        <Row className="justify-content-md-center px-4 py-5">
          <Row className="justify-content-md-center py-4">
            <Col md={5} className="py-3">
              <Stack direction="vertical" gap={3}>
                <div className="d-flex align-items-center">
                  <Image
                    src={imgBulletPoint}
                    className="pe-3"
                    style={{ height: '15px' }}
                  />
                  <span className={styles.homeSectionTitle}>POTENTIAL</span>
                </div>
                <div>
                  <span className={styles.homeSectionHeadline}>
                    Who can benefit
                  </span>
                </div>
              </Stack>
            </Col>
            <Col md={5} className="py-3"></Col>
          </Row>
          <Row className="justify-content-md-center pb-4">
            <Col md={5} className="mb-4">
              <div>
                <Image rounded src={imgBrand} width={'77%'} />
              </div>
            </Col>
            <Col md={5}>
              <Stack direction="vertical" gap={3}>
                <div className="mb-3">
                  <div className={styles.homeSectionTitle + ' mb-3'}>
                    Brands
                  </div>
                  <div className={styles.homeSectionContent}>
                    Access a wide network of suppliers to drive innovation,
                    secure competitive pricing, and enhance control over
                    sourcing. Our platform simplifies finding and collaborating
                    with the right suppliers, boosting product development and
                    market responsiveness.
                  </div>
                </div>
              </Stack>
            </Col>
          </Row>
          <Row className="justify-content-md-center pb-5">
            <Col md={5} className="mb-4">
              <div>
                <Image rounded src={imgSupplier} width={'77%'} />
              </div>
            </Col>
            <Col md={5}>
              <Stack direction="vertical" gap={3}>
                <div className="mb-3">
                  <div className={styles.homeSectionTitle + ' mb-3'}>
                    Suppliers
                  </div>
                  <div className={styles.homeSectionContent}>
                    Increase visibility to potential clients and compete for
                    relevant projects. Showcase your capabilities in a
                    structured environment, expand your client base, and engage
                    in transparent transactions to foster growth and new
                    business opportunities.
                  </div>
                </div>
              </Stack>
            </Col>
          </Row>
        </Row>
        {/*<hr className='mx-4 my-4' />
            <Row className="mx-0 py-5 text-center">
                <span className={styles.homeTestimonialSectionTitle + " pt-2 pb-4"}>Our Trusted Partners</span>
                <Col md={{span: 10, offset: 1}} className="px-4 pb-4">
                    <div style={{ height: "100%", backgroundColor: "#B9C9EF" }} className='d-flex'>
                        <div className="mx-auto pb-3">
                            <Image src={imgCompany1} className='py-2 px-3' style={{maxHeight: "120px"}}></Image>
                            <Image src={imgCompany2} className='py-2 px-3' style={{maxHeight: "120px"}}></Image>
                            <Image src={imgCompany3} className='py-2 px-3' style={{maxHeight: "120px"}}></Image>
                            <Image src={imgCompany4} className='py-2 px-3' style={{maxHeight: "50px"}}></Image>
                            <Image src={imgCompany5} className='py-2 px-3' style={{maxHeight: "55px"}}></Image>
                            <Image src={imgCompany6} className='py-2 px-3' style={{maxHeight: "55px"}}></Image>
                        </div>
                    </div>
                </Col>
            </Row>*/}
        <Footer />
      </Container>
    </>
  )
}
