import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Card
} from 'react-bootstrap'
import { updateBuyerProfile } from '../services/buyerServices'
import { auth } from '../firebase'
import { getFile } from '../services/fileStorageServices'
import imgLogo from '../images/logo_white.png'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import useUser from '../hooks/useUser'
import { useToast } from '../ToastContext';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)

export const BuyerProfile = props => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const buyer = useUser(auth?.currentUser?.email)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [title, setTitle] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  const [logoFileRefData, setLogoFilesRefData] = useState()
  const [logoFiles, setLogoFiles] = useState([])

  useEffect(() => {
    if (buyer) {
      setFirstName(buyer?.firstName)
      setLastName(buyer?.lastName)
      if (buyer?.phone && buyer?.phone.length > 0) {
        setPhone(buyer?.phone)
      }
      setCompany(buyer?.company)
      setTitle(buyer?.title)
      if (buyer?.officeAddress) {
        setAddress1(buyer?.officeAddress?.address1)
        setAddress2(buyer?.officeAddress?.address2)
        setCity(buyer?.officeAddress?.city)
        setState(buyer?.officeAddress?.state)
        setZip(buyer?.officeAddress?.zip)
      }
      setLogoFilesRefData(buyer?.logoFileRefData)
    }
  }, [buyer])

  useEffect(() => {
    async function designFilesFetchData() {
      try {
        if (logoFileRefData) {
          var files = []
          for (const data of logoFileRefData) {
            const response = await getFile(data.blobName)
            var base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            files.push(`data:image/png;base64,${base64String}`)
          }
          setLogoFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    designFilesFetchData()
  }, [logoFileRefData])

  function getOfficeAddressObj() {
    return {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zip
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    try {
      await updateBuyerProfile(
        firstName,
        lastName,
        phone,
        company,
        title,
        getOfficeAddressObj(),
        logoFiles
      )

      addToast('Profile saved successfully', 'success')

      if (props.navigateTo) {
        navigate(props.navigateTo)
        window.location.reload()
      } else {
        props.sendToParent()
      }
    } catch {
      addToast('Submitting buyer profile failed, please try again.', 'danger')
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={4} className="px-3">
            <Card style={{ backgroundColor: '#3F46D6', height: '100%' }}>
              <Card.Body className="px-5">
                <p
                  className="py-5"
                  style={{ color: '#FFFFFF', fontSize: '40px' }}
                >
                  We solve all your packaging needs.
                </p>
                <p style={{ color: '#FFFFFF', fontSize: '16px' }}>
                  Our mission is to revolutionize the cosmetic packaging supply
                  chain by creating a transparent, agile, and high-performing
                  ecosystem that seamlessly connects Suppliers and Brands.
                </p>
              </Card.Body>
              <Card.Footer style={{ borderWidth: 0 }}>
                <Image src={imgLogo} fluid className="px-4 py-4" />
              </Card.Footer>
            </Card>
          </Col>
          <Col md={8} className="px-3">
            <Form onSubmit={onSubmit}>
              <Row className="my-5">
                <h4 className="mb-2">Buyer</h4>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="firstName.control">
                      <Form.Label className={styles.listItemLabel}>
                        First name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="lastName.control">
                      <Form.Label className={styles.listItemLabel}>
                        Last name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="email.control">
                      <Form.Label className={styles.listItemLabel}>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        value={auth?.currentUser?.email}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="phone.control">
                      <Form.Label className={styles.listItemLabel}>
                        Phone (optional)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <Row className="mb-5">
                <h4 className="mb-2">Company</h4>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="company.control">
                      <Form.Label className={styles.listItemLabel}>
                        Company name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        required
                        disabled={!buyer?.role?.admin}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="title.control">
                      <Form.Label className={styles.listItemLabel}>
                        Position title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Form.Group className="mb-2" controlId="address1.control">
                      <Form.Label className={styles.listItemLabel}>
                        Office address 1
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address1}
                        placeholder="Street name"
                        onChange={e => setAddress1(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-2" controlId="address2.control">
                      <Form.Label className={styles.listItemLabel}>
                        Address 2 (optional)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address2}
                        placeholder="Suite, unit, etc."
                        onChange={e => setAddress2(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="city.control">
                      <Form.Label className={styles.listItemLabel}>
                        City
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="state.control">
                      <Form.Label className={styles.listItemLabel}>
                        State
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={state}
                        onChange={e => setState(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="zip.control">
                      <Form.Label className={styles.listItemLabel}>
                        Zip
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={zip}
                        onChange={e => setZip(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <Row className="mb-5">
                <h4 className="mb-2">Company logo</h4>
                <Row>
                  <FilePond
                    files={logoFiles}
                    instantUpload={false}
                    onupdatefiles={setLogoFiles}
                    allowMultiple={false}
                    maxFiles={1}
                    dropOnPage={true}
                    server={null}
                    name="files"
                    labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                    imagePreviewHeight={150}
                    id="logoFile.control"
                    acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
                    required
                  />
                </Row>
              </Row>
              <Row className="mb-5">
                <Row>
                  <div>
                    <Button type="submit" className={styles.button}>
                      <span className={styles.buttonLabel}>Submit</span>
                    </Button>
                  </div>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
