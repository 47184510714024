export enum PageRoutes {
  HOME = '/home',
  BROWSE_PROJECTS = '/browseProjects',
  MY_PROJECTS = '/myProjects',
  MY_QUOTES = '/myQuotes',
  PROJECT_PROGRESS_BUYER = '/projectProgressBuyer',
  PROJECT_PROGRESS_SUPPLIER = '/projectProgressSupplier',
  PROJECT_UPLOAD = '/projectUpload',
  PROJECT_EDIT = '/projectEdit',
  ENGAGED_PROEJECT_BUYER_VIEW = '/engagedProjectBV',
  ENGAGED_PROEJECT_SUPPLIER_VIEW = '/engagedProjectSV',
  QUOTES_LIST = '/quotesList',
  PROJECT_DETAILS = '/projectDetails',
  QUOTE_DETAILS = '/quoteDetails',
  INIT_ASSESSMENT = '/initAssessment',
  REGISTER_PROFILE = '/registerProfile',
  PRIVACY_POLICY = '/privacyPolicy',
  REGISTER = '/register',
  LOGIN = '/login',
  UPDATE_PASSWORD = '/updatePassword',
  NEWS = '/news',
  DEMO = '/demo',
  DEMO_REQUESTS = '/demoRequests'
}

// project status
export const STATUS_PROJECT_DRAFT = 'Draft project' // when project is created by buyer, before submit for supplier quotes
export const STATUS_PROJECT_INIT_ASSESSMENT = 'Initial assessment' // when project is submitted for Package Maven review
export const STATUS_PROJECT_ACCEPTING_QUOTES = 'Accepting quotes' // when project is submiited for quotes by buyer, waiting for supplier quotes
export const STATUS_PROJECT_SUPPLIER_SELECTED = 'Supplier selected' // when buyer completes quote selection
export const STATUS_PROJECT_CANCELLED = 'Cancelled' // when project is cancelled/closed by buyer after receiving supplier quotes and before completion
export const STATUS_PROJECT_COMPLETE = 'Complete' // when project is complete with delivery of products
export const STATUS_PROJECT_REOPENED = 'Reopened' // when project was cancelled and buyer reopens the project, treat it same as "draft" status

// quote status
export const STATUS_QUOTE_DRAFT = 'Draft quote'
export const STATUS_QUOTE_INIT_ASSESSMENT = 'Initial assessment' // currently not used, quote goes to submitted status when supplier submits
export const STATUS_QUOTE_SUBMITTED = 'Quote submitted' // after review is complete, buyers can view submitted quotes
export const STATUS_QUOTE_SELECTED = 'Quote selected'
export const STATUS_QUOTE_ADJUSTED = 'Quote adjusted' // updated quote after sample feedback from buyer during non-deco and pps steps
export const STATUS_QUOTE_ADJUSTED_NOT_SELECTED =
  'Quote not selected during adjustment'
export const STATUS_QUOTE_COMPLETE = 'Complete'
export const STATUS_QUOTE_CANCELED = 'Canceled'

// engaged quote status
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED =
  'Non-deco sample requested'
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT =
  'Non-deco sample in transit'
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED =
  'Non-deco sample delivered'
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST =
  'Non-deco sample adjustment needed'
//export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_FEEDBACK_SUBMITTED = "Non deco sample feedback submitted";
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUSTED_QUOTE_SELECTED =
  'Non-deco sample adjusted quote selected'
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE =
  'Non-deco sample complete'

export const STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW =
  'Design submitted for review'
export const STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE =
  'Design review complete'

export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED =
  'Pre-prod sample requested'
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT =
  'Pre-prod sample in transit'
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED =
  'Pre-prod sample delivered'
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST =
  'Pre-prod sample adjustment needed'
//export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_FEEDBACK_SUBMITTED = "Pre-prod sample feedback submitted";
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUSTED_QUOTE_SELECTED =
  'Pre-prod sample adjusted quote selected'
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE =
  'Pre-prod sample complete'

// message type
export const TYPE_MSG_PRJ_ALL = 'All project messages'
export const TYPE_MSG_QUOTE_ALL = 'All quote messages'
export const TYPE_MSG_PRJ_INIT_ASESSMENT = 'Project initial assessment'
export const TYPE_MSG_QUOTE_COMMENTS = 'Quote comments'
export const TYPE_MSG_QUOTE_SAMPLE_NON_DECO_REQUEST = 'Sample non-deco request'
export const TYPE_MSG_QUOTE_SAMPLE_NON_DECO_FEEDBACK =
  'Sample non-deco feedback'
export const TYPE_MSG_QUOTE_DESIGN_REVIEW = 'Design review feedback'
export const TYPE_MSG_QUOTE_SAMPLE_DECO_REQUEST = 'Sample deco request'
export const TYPE_MSG_QUOTE_SAMPLE_DECO_FEEDBACK = 'Sample deco feedback'

// notification type
export const NOTI_TYPE_WELCOME = 'welcome'
export const NOTI_TYPE_BUYER_PROJECT_CREATED = 'buyer-project-created'
export const NOTI_TYPE_BUYER_PROJECT_POSTED = 'buyer-project-posted'
export const NOTI_TYPE_SUPPLIER_PROJECT_POSTED = 'supplier-project-posted'
export const NOTI_TYPE_BUYER_QUOTE_SUBMITTED = 'buyer-quote-submitted'
export const NOTI_TYPE_SUPPLIER_QUOTE_SUBMITTED = 'supplier-quote-submitted'
export const NOTI_TYPE_BUYER_QUOTE_SELECTED = 'buyer-quote-selected'
export const NOTI_TYPE_SUPPLIER_QUOTE_SELECTED = 'supplier-quote-selected'
export const NOTI_TYPE_BUYER_SAMPLE_REQUESTED = 'buyer-sample-requested'
export const NOTI_TYPE_SUPPLIER_SAMPLE_REQUESTED = 'supplier-sample-requested'
export const NOTI_TYPE_BUYER_QUOTE_ADJUST_SUBMITTED =
  'buyer-quote-adjust-submitted'
export const NOTI_TYPE_SUPPLIER_SAMPLE_SHIPPED = 'supplier-sample-shipped'
export const NOTI_TYPE_BUYER_SAMPLE_SHIPPED = 'buyer-sample-shipped'
export const NOTI_TYPE_BUYER_SAMPLE_DELIVERY_CONFIRMED =
  'buyer-sample-delivery-confirmed'
export const NOTI_TYPE_BUYER_DESIGN_SUBMITTED = 'buyer-design-submitted'
export const NOTI_TYPE_SUPPLIER_DESIGN_READY_FOR_REVIEW =
  'supplier-design-ready-for-review'
export const NOTI_TYPE_BUYER_DESIGN_ACCEPTED = 'buyer-design-accepted'
export const NOTI_TYPE_PROJECT_COMMENTS = 'comments-project'
export const NOTI_TYPE_QUOTE_COMMENTS = 'comments-quote'

// page links
export const LINK_BROWSE_PROJECTS =
  'https://www.packagemaven.com/browseProjects'
export const LINK_MY_PROJECTS = 'https://www.packagemaven.com/myProjects'
export const LINK_MY_QUOTES = 'https://www.packagemaven.com/myQuotes'
export const LINK_PROJECT_DETAIL =
  'https://www.packagemaven.com/projectDetails/' // requires projectId
export const LINK_BUYER_PROGRESS =
  'https://www.packagemaven.com/projectProgressBuyer/' // requires projectId
export const LINK_SUPPLIER_PROGRESS =
  'https://www.packagemaven.com/projectProgressSupplier/' // required project Id and ?quoteId= quote Id
