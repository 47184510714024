import axios from 'axios'
import { fileTypeFromBuffer } from 'file-type'
//import { readChunk } from 'read-chunk';

// This function retrieves file from express server. Express server downloads the file from Google Cloud Storage and saves into memory.
// Returns requested file.
export async function getFile(blobName) {
  let results
  if (blobName && blobName.length > 0) {
    try {
      await axios
        .get(`${process.env.REACT_APP_SERVER_GET_FILE_API_ENDPOINT}/`, {
          params: {
            blobName: blobName
          }
          //responseType: "blob"
        })
        .then(async res => {
          //console.log(blobName, " => ", res.data);
          results = res.data
          console.log('Retrieved file successfully.')

          //console.log("response", res);
          //getFileType(results.data[0]);
        })
        .catch(err => {
          console.log(err)
        })
    } catch (e) {
      console.error('Error getting file: ', e)
    }
  }
  return results
}

export async function getFileBlob(blobName) {
  let results
  if (blobName && blobName.length > 0) {
    try {
      await axios
        .get(`${process.env.REACT_APP_SERVER_GET_FILE_API_ENDPOINT}/`, {
          params: {
            blobName: blobName
          },
          responseType: 'blob'
        })
        .then(async res => {
          //console.log(blobName, " => ", res.data);
          results = res.data
          console.log('Retrieved file blob successfully.')
        })
        .catch(err => {
          console.log(err)
        })
    } catch (e) {
      console.error('Error getting file: ', e)
    }
  }
  return results
}

export async function getFileExtension(fileData) {
  const { ext } = await fileTypeFromBuffer(fileData)

  return ext
}

/* // To be placed in server, not client
export async function downloadFiles(blobName) {
    // The ID of your GCS bucket
    const bucketName = 'your-unique-bucket-name';

    // The ID of the GCS folder to download. The folder will be downloaded to the local path of the executing code.
    const folderName = 'your-folder-name';

    // Imports the Google Cloud client library
    const {Storage, TransferManager} = require('@google-cloud/storage');

    // Creates a client
    const storage = new Storage();

    // Creates a transfer manager client
    const transferManager = new TransferManager(storage.bucket(bucketName));

    await transferManager.downloadManyFiles(folderName);

    console.log(
        `gs://${bucketName}/${folderName} downloaded to ${folderName}.`
    );
}
*/
