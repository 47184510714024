import styles from '../styles/styles.module.css'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
  Card
} from 'react-bootstrap'
import { Footer } from '../components/Footer'
import { DemoRequestForm } from '../types'
import { requestDemo } from '../services/demoService'
import { useState } from 'react'

const BookDemo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<DemoRequestForm>({ mode: 'onChange' })
  const [submitted, setSubmitted] = useState(false)

  const onSubmit: SubmitHandler<DemoRequestForm> = async formData => {
    try {
      await requestDemo(formData)

      setSubmitted(true)
    } catch (err) { }
  }

  return (
    <Container fluid={true} className={styles.backgroundColor + " d-flex flex-column min-vh-100 px-0"}>
      <Container fluid="xl" className="flex-grow-1 px-0 my-5 bookDemo">
        <Card className='px-5'>
          <Row className="px-0 py-0">
            <Col lg={6} className="text-xl-end text-center my-5 px-5">
              <Image rounded src={require('../images/home_our_service.png')} />
            </Col>
            <Col lg={6} className="content align-content-top my-5">
              <Stack direction="vertical" gap={3} className="mx-5">
                <div>
                  <h1 className={styles.homeSectionHeadline + " mb-4"}>Experience the Future of Packaging Sourcing</h1>
                  <div className={styles.homeSectionContent + " mb-3"}>
                    Discover how our platform connects brands with trusted suppliers,
                    ensures quality, and simplifies every step from design to
                    delivery. Book a demo to see how we streamline your sourcing
                    needs.
                  </div>
                </div>
                {submitted ? (
                  <div className={styles.homeSectionContent + " py-3"}>
                    Thank you for requesting a demo. Our representative will reach
                    out to you soon.
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 me-5">
                      <Form.Label className={styles.detailItemLabel} htmlFor="firstNameControl">
                        First name <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="firstNameControl"
                        {...register('firstName', {
                          required: 'First name is required'
                        })}
                      />
                      {errors.firstName && (
                        <p className="error">{errors.firstName.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 me-5">
                      <Form.Label className={styles.detailItemLabel} htmlFor="lastNameControl">
                        Last name <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="lastNameControl"
                        {...register('lastName', {
                          required: 'Last name is required'
                        })}
                      />
                      {errors.lastName && (
                        <p className="error">{errors.lastName.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 me-5">
                      <Form.Label className={styles.detailItemLabel} htmlFor="emailControl">
                        Email <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="emailControl"
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Invalid email address'
                          }
                        })}
                      />
                      {errors.email && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4 me-5">
                      <Form.Label className={styles.detailItemLabel} htmlFor="companyNameControl">
                        Company name <span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="companyNameControl"
                        {...register('companyName', {
                          required: 'Company name is required'
                        })}
                      />
                      {errors.companyName && (
                        <p className="error">{errors.companyName.message}</p>
                      )}
                    </Form.Group>
                    <Button className={styles.button} type="submit" disabled={!isValid}>
                      Submit
                    </Button>
                  </Form>
                )}
              </Stack>
            </Col>
          </Row>
        </Card>
      </Container>
      <Footer />
    </Container>
  )
}

export default BookDemo
