import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Stack,
  Offcanvas,
  Button,
  Toast,
  ToastContainer,
  Spinner,
  Accordion,
  Alert
} from 'react-bootstrap'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment'
import { getSupplierQuotes } from '../services/supplierServices'
import { getFile } from '../services/fileStorageServices'
import * as constants from '../constants'
import { QuoteSubmitForm } from './QuoteSubmitForm'
import { FileGallery } from './FileGallery'
import useUser from '../hooks/useUser'
import { useToast } from '../ToastContext'

export const ProjectDetails = ({ project, allowQuoteSubmit }) => {
  const { addToast } = useToast()
  const [user] = useAuthState(auth)
  const userData = useUser(user?.email)
  const buyer = useUser(project.createdBy)

  const brand = project.brand
  const projectName = project.name
  const initOrderQuantity = project.initOrderQuantity
  const capacity = project.capacity
  const acceptLargerQuantityQuotes = project.acceptLargerQuantityQuotes
  const targetUnitCost = project.targetUnitCost
  const isSustainbabilityRequired = project.isSustainbabilityRequired
  const sustainabilityRequirements = project.sustainabilityRequirements
  const benchmarkLink = project.benchmarkLink

  const baseType = project.base?.type
  const baseMaterial = project.base?.material
  const baseDeco = project.base?.deco
  const baseFinish = project.base?.finish

  const closureType = project.closure?.type
  const closureMaterial = project.closure?.material
  const closureDeco = project.closure?.deco
  const closureFinish = project.closure?.finish

  const applicatorType = project.applicator?.type

  const isLabelNeeded = project.label?.isNeeded
  const labelMaterial = project.label?.material
  const decoDesignNeeds = project.decoDesignNeeds
  const designFlexibility = project.designFlexibility
  const otherRequirements = project.otherRequirements

  const formulationType = project.formulation?.type
  const formulationViscosity = project.formulation?.viscosity
  const canShareFormulation = project.formulation?.canShare
  const multiFormulationPackaging = project.multiFormulationPackaging
  const hasMultipleSKUs = project.hasMultipleSKUs
  const hasContractManufacturer = project.hasContractManufacturer

  const hasPartnerFreightLogisticsCompany =
    project.hasPartnerFreightLogisticsCompany
  const preferredIncoTerm = project.preferredIncoTerm

  const deliveryDate = project.deliveryDate
  const receivingLocation = project.receivingLocation

  //const supplierAuditCertification = project.supplierQualifications?.auditCertification;
  //const supplierSustainability = project.supplierQualifications?.sustainability;
  //const supplierService = project.supplierQualifications?.service;
  //const supplierCapacity = project.supplierQualifications?.capacity;
  //const supplierInnovation = project.supplierQualifications?.innovation;

  const fileRefData = project.fileRefData
  const [designFiles, setDesignFiles] = useState([])
  const [designFilesLoading, setDesignFilesLoading] = useState(true)

  // offcanvas properties
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const toggleShow = () => setShow(s => !s)

  const [prevSubmittedQuote, setPrevSubmittedQuote] = useState() // previsouly submitted quote if current user is a supplier
  const [quoteSubmitted, setQuoteSubmitted] = useState(false)
  const [quoteStateChanged, setQuoteStateChanged] = useState(false)

  useEffect(() => {
    async function designFilesFetchData() {
      try {
        if (fileRefData) {
          var files = []
          for (const data of fileRefData) {
            const response = await getFile(data.blobName)
            const fileExt = data?.blobName
              ?.substring(data?.blobName?.lastIndexOf('.') + 1)
              ?.toLowerCase()
            const base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            const fileObj = {
              base64String: base64String,
              fileExt: fileExt
            }
            files.push(fileObj)
          }
          setDesignFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    designFilesFetchData()
  }, [fileRefData])

  useEffect(() => {
    if (fileRefData && fileRefData.length > 0) {
      if (!designFiles || designFiles?.length === 0) {
        setDesignFilesLoading(true)
      } else {
        setDesignFilesLoading(false)
      }
    } else {
      // case where design files are not uploaded
      setDesignFilesLoading(false)
    }
  }, [designFiles, fileRefData])

  useEffect(() => {
    async function supplierQuoteFetchData() {
      try {
        const response = await getSupplierQuotes(
          auth?.currentUser?.email,
          project.id
        )
        if (response && response?.length > 0) {
          setPrevSubmittedQuote(response[0])
          if (response[0]?.status !== constants.STATUS_QUOTE_DRAFT) {
            setQuoteSubmitted(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (allowQuoteSubmit) {
      supplierQuoteFetchData()
    }
  }, [project, quoteStateChanged])

  const handleQuoteSubmittedFromChild = e => {
    setQuoteSubmitted(true)
    addToast('Quote submitted successfully.', 'success')
    handleClose()
  }

  const handleDraftQuoteSavedFromChild = e => {
    setQuoteStateChanged(!quoteStateChanged)
  }

  return (
    <>
      <Container fluid={true}>
        {allowQuoteSubmit && (
          <Offcanvas
            show={show}
            placement="end"
            onHide={handleClose}
            scroll={true}
            backdrop={false}
            className="w-75"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Quote submit</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <QuoteSubmitForm
                projectId={project.id}
                acceptLargerQuantityQuotes={acceptLargerQuantityQuotes}
                initOrderQuantity={initOrderQuantity}
                designFlexibility={designFlexibility}
                prevSubmittedQuote={prevSubmittedQuote}
                sendToParentQuoteSubmitted={handleQuoteSubmittedFromChild}
                sendToParentDraftQuoteSaved={handleDraftQuoteSavedFromChild}
              />
            </Offcanvas.Body>
          </Offcanvas>
        )}
        {allowQuoteSubmit ? (
          <Row className="px-3 pt-3 text-end">
            <div>
              <Button
                className={styles.button + ' px-4 py-2'}
                onClick={toggleShow}
                disabled={quoteSubmitted ? true : false}
              >
                {quoteSubmitted ? 'Quote submitted' : 'Submit quote'}
              </Button>
            </div>
          </Row>
        ) : (
          <></>
        )}
        <Row className="px-3 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Stack direction="horizontal" gap={5}>
                  <h4>Project</h4>
                </Stack>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="px-2">
                  {user && userData?.role?.admin && (
                    <Col xs={12}>
                      <Alert variant="danger" className="mt-2 my-2">
                        <Alert.Heading>Admin only</Alert.Heading>
                        <Stack direction="vertical" gap={1}>
                          <div className={styles.detailItemLabel}>Author</div>
                          <div className={styles.detailItemValue}>
                            {buyer?.firstName +
                              ' ' +
                              buyer?.lastName +
                              ' (' +
                              buyer?.email +
                              ')'}
                          </div>
                        </Stack>
                      </Alert>
                    </Col>
                  )}
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Project name</div>
                      <div className={styles.detailItemValue}>
                        {projectName}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Brand</div>
                      <div className={styles.detailItemValue}>{brand}</div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Initial order quantity
                      </div>
                      <div className={styles.detailItemValue}>
                        {initOrderQuantity}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Capacity</div>
                      <div className={styles.detailItemValue}>
                        {capacity} ml
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Accepting quotes for larger order quantity
                      </div>
                      <div className={styles.detailItemValue}>
                        {acceptLargerQuantityQuotes
                          ? acceptLargerQuantityQuotes
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Target unit cost  (Unit cost = component + finish + deco)
                      </div>
                      <div className={styles.detailItemValue}>
                        {targetUnitCost
                          ? '$' + targetUnitCost
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Does project have sustainability requirements?
                      </div>
                      <div className={styles.detailItemValue}>
                        {isSustainbabilityRequired
                          ? isSustainbabilityRequired
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Sustainability requirements
                      </div>
                      <div className={styles.detailItemValue}>
                        {sustainabilityRequirements
                          ? sustainabilityRequirements
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Benchmark link
                      </div>
                      <div className={styles.detailItemValue}>
                        {benchmarkLink ? (
                          <a
                            href={
                              benchmarkLink?.startsWith('https://')
                                ? benchmarkLink
                                : 'https://' + benchmarkLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                          >
                            <span>{benchmarkLink}</span>
                          </a>
                        ) : (
                          <span>Not specified</span>
                        )}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="px-3 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Stack direction="horizontal" gap={5}>
                  <h4>Design</h4>
                </Stack>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="px-2">
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Base type</div>
                      <div className={styles.detailItemValue}>{baseType}</div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Base material
                      </div>
                      <div className={styles.detailItemValue}>
                        {baseMaterial}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Base finish</div>
                      <div className={styles.detailItemValue}>{baseFinish}</div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Base deco</div>
                      <div className={styles.detailItemValue}>{baseDeco}</div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Closure type</div>
                      <div className={styles.detailItemValue}>
                        {closureType}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Closure material
                      </div>
                      <div className={styles.detailItemValue}>
                        {closureMaterial}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Closure finish
                      </div>
                      <div className={styles.detailItemValue}>
                        {closureFinish}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Closure deco</div>
                      <div className={styles.detailItemValue}>
                        {closureDeco}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Applicator type
                      </div>
                      <div className={styles.detailItemValue}>
                        {applicatorType ? applicatorType : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>Label needed</div>
                      <div className={styles.detailItemValue}>
                        {isLabelNeeded ? isLabelNeeded : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Label material
                      </div>
                      <div className={styles.detailItemValue}>
                        {labelMaterial ? labelMaterial : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Decoration design needs
                      </div>
                      <div className={styles.detailItemValue}>
                        {decoDesignNeeds ? decoDesignNeeds : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Design flexibility
                        <span style={{ fontStyle: 'italic' }}>
                          {' '}
                          - Open to similar design or new tooling
                        </span>
                      </div>
                      <div className={styles.detailItemValue}>
                        {designFlexibility
                          ? designFlexibility
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                {designFilesLoading ? (
                  <Row className="px-2">
                    <Col className="py-3">
                      <div className={styles.detailItemLabel}>Design files</div>
                      <Row className="mx-3 my-3">
                        Loading ...
                        <Spinner
                          animation="border"
                          variant="secondary"
                          role="status"
                          className="mx-3"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  designFiles &&
                  designFiles?.length > 0 && (
                    <Row className="px-2 pb-3">
                      <Col className="py-3">
                        <div className={styles.detailItemLabel}>
                          Design files
                        </div>
                        <FileGallery files={designFiles} />
                        {/*<ImageGallery 
                                            items={designFiles} 
                                            showThumbnails={true} 
                                            showNav={true}
                                            thumbnailPosition={'bottom'} 
                                            disableThumbnailScroll={true}
                                            showPlayButton={false} 
                                            showIndex={true} />*/}
                      </Col>
                    </Row>
                  )
                )}
                <Row className="px-2">
                  <Col className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Other requirements
                      </div>
                      <div className={styles.detailItemValue}>
                        {otherRequirements
                          ? otherRequirements
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="px-3 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Stack direction="horizontal" gap={5}>
                  <h4>Formulation</h4>
                </Stack>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="px-2">
                  <Col md={6} lg={3} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Formulation type
                      </div>
                      <div className={styles.detailItemValue}>
                        {formulationType ? formulationType : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Formulation viscosity and pH level
                      </div>
                      <div className={styles.detailItemValue}>
                        {formulationViscosity
                          ? formulationViscosity
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Formulation can be shared with suppliers
                      </div>
                      <div className={styles.detailItemValue}>
                        {canShareFormulation
                          ? canShareFormulation
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Using this packaging for multiple formulations
                      </div>
                      <div className={styles.detailItemValue}>
                        {multiFormulationPackaging
                          ? multiFormulationPackaging
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Has multiple SKUs
                      </div>
                      <div className={styles.detailItemValue}>
                        {hasMultipleSKUs ? hasMultipleSKUs : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className='px-2'>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Has contract manufacturer for formulation development and filling
                      </div>
                      <div className={styles.detailItemValue}>
                        {hasContractManufacturer
                          ? hasContractManufacturer
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="px-3 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Stack direction="horizontal" gap={5}>
                  <h4>Delivery</h4>
                </Stack>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Required delivery date
                      </div>
                      <div className={styles.detailItemValue}>
                        {moment
                          .unix(deliveryDate?.seconds)
                          .format('MM/DD/YYYY')}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Receiving location (City, State)
                      </div>
                      <div className={styles.detailItemValue}>
                        {receivingLocation}
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Has parter freight company
                      </div>
                      <div className={styles.detailItemValue}>
                        {hasPartnerFreightLogisticsCompany
                          ? hasPartnerFreightLogisticsCompany
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                  <Col md={6} className="py-3">
                    <Stack direction="vertical" gap={1}>
                      <div className={styles.detailItemLabel}>
                        Preferred Inco Term
                      </div>
                      <div className={styles.detailItemValue}>
                        {preferredIncoTerm
                          ? preferredIncoTerm
                          : 'Not specified'}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        {/*<Row className="px-3 pb-3">
                <Card>
                    <Card.Body>
                        <Card.Title>Supplier qualifications</Card.Title>
                        <Row>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Audit / Certification</div>
                                    <div>{supplierAuditCertification ? supplierAuditCertification : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Sustainability</div>
                                    <div>{supplierSustainability ? supplierSustainability : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Service</div>
                                    <div>{supplierService ? supplierService : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Capacity</div>
                                    <div>{supplierCapacity ? supplierCapacity : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Innovation</div>
                                    <div>{supplierInnovation ? supplierInnovation : "Not specified"}</div>
                                </Stack>
                            </Col>
                        </Row>
                    </Card.Body>                            
                </Card>
            </Row>*/}
      </Container >
    </>
  )
}
