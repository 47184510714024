import styles from '../styles/styles.module.css'
import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Stack,
  Button,
  Form,
  Alert,
  Spinner,
  Image,
  Nav,
  ProgressBar,
  Offcanvas,
  OverlayTrigger,
  Popover
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getSupplierProjectsQuotesData } from '../services/supplierServices'
import {
  getEngagedQuoteStatus,
  getSelectedQuote
} from '../services/buyerServices' //updateProjectStatus
//import moment from 'moment';
import * as constants from '../constants'
import { PageRoutes } from '../constants'
import { QuoteSubmitForm } from '../components/QuoteSubmitForm'
//import Messages from '../components/Messages';
import iconMessage from '../images/icons/communication.png'
import iconInfo from '../images/icons/info.png'
import { getLatestStatusLabel } from '../services/statusServices'
import iconInstruction from '../images/icons/instruction.png'
import useUser from '../hooks/useUser'

export const MyQuotes = () => {
  // logged in user
  const [user, userLoading] = useAuthState(auth)
  const userData = useUser(user?.email)

  const [myProjectsQuotesData, setMyProjectQuotesData] = useState()
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState()
  //const [previewImageSources, setPreviewImageSources] = useState([]);
  //const [imgLoading, setImgLoading] = useState(true);
  const [loading, setLoading] = useState(true)

  // offcanvas properties
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setSelectedProjId('')
    setSelectedProjAcceptLargerQueantityQuotes('')
    setSelectedProjInitOrderQuantity('')
    setsSlectedProjDesignFlexibility('')
    setSelectedProjPrevSubmittedQuote()
    setShow(false)
  }
  const toggleShow = () => setShow(s => !s)
  const [quoteStateChanged, setQuoteStateChanged] = useState(false)

  const [selectedProjId, setSelectedProjId] = useState('')
  const [
    selectedProjAcceptLargerQueantityQuotes,
    setSelectedProjAcceptLargerQueantityQuotes
  ] = useState('')
  const [selectedProjInitOrderQuantity, setSelectedProjInitOrderQuantity] =
    useState('')
  const [selectedProjDesignFlexibility, setsSlectedProjDesignFlexibility] =
    useState('')
  const [selectedProjPrevSubmittedQuote, setSelectedProjPrevSubmittedQuote] =
    useState()

  useEffect(() => {
    async function myQuotesFetchData() {
      try {
        const response = await getSupplierProjectsQuotesData(
          auth?.currentUser?.email
        )
        setMyProjectQuotesData(response)
      } catch (error) {
        console.log(error)
      }
    }
    async function allQuotesFetchData() {
      try {
        const response = await getSupplierProjectsQuotesData('admin')
        setMyProjectQuotesData(response)
      } catch (error) {
        console.log(error)
      }
    }

    if (userData) {
      if (userData?.role?.admin) {
        allQuotesFetchData()
      } else {
        myQuotesFetchData()
      }
    }
  }, [quoteStateChanged, userData])

  useEffect(() => {
    if (!myProjectsQuotesData || !userData) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [myProjectsQuotesData, userData])

  useEffect(() => {
    async function getEngagedQuoteStatusHistoryData() {
      var tempSelectedQuoteStatusHistory = []
      if (myProjectsQuotesData) {
        for (const pqd of myProjectsQuotesData) {
          try {
            const engagedQuote = await getSelectedQuote(pqd?.project?.id)
            if (engagedQuote && engagedQuote.length > 0) {
              const response = await getEngagedQuoteStatus(engagedQuote[0]?.id)
              tempSelectedQuoteStatusHistory.push({
                projectId: pqd?.project?.id,
                quoteId: engagedQuote[0]?.id,
                statusHistory: response
              })
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
      setEngagedQuoteStatusHistory(tempSelectedQuoteStatusHistory)
    }
    getEngagedQuoteStatusHistoryData()
  }, [myProjectsQuotesData])

  const handleQuoteEdit = e => {
    e.preventDefault()
    const projectId = e.currentTarget.getAttribute('data-project-id')
    const selectedProjQuoteData = myProjectsQuotesData?.filter(
      data => data?.project?.id === projectId
    )
    setSelectedProjId(projectId)
    setSelectedProjAcceptLargerQueantityQuotes(
      selectedProjQuoteData[0]?.project?.acceptLargerQuantityQuotes
    )
    setSelectedProjInitOrderQuantity(
      selectedProjQuoteData[0]?.project?.initOrderQuantity
    )
    setsSlectedProjDesignFlexibility(
      selectedProjQuoteData[0]?.project?.designFlexibility
    )
    setSelectedProjPrevSubmittedQuote(selectedProjQuoteData[0]?.quote)

    toggleShow()
  }

  const handleQuoteSubmittedFromChild = e => {
    setQuoteStateChanged(!quoteStateChanged)
  }

  const handleDraftQuoteSavedFromChild = e => {
    setQuoteStateChanged(!quoteStateChanged)
  }

  return (
    <>
      <Container fluid={true}>
        <Offcanvas
          show={show}
          placement="end"
          onHide={handleClose}
          scroll={true}
          backdrop={false}
          className="w-75"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Quote submit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <QuoteSubmitForm
              projectId={selectedProjId}
              acceptLargerQuantityQuotes={
                selectedProjAcceptLargerQueantityQuotes
              }
              initOrderQuantity={selectedProjInitOrderQuantity}
              designFlexibility={selectedProjDesignFlexibility}
              prevSubmittedQuote={selectedProjPrevSubmittedQuote}
              sendToParentQuoteSubmitted={handleQuoteSubmittedFromChild}
              sendToParentDraftQuoteSaved={handleDraftQuoteSavedFromChild}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="px-2 pt-3 pb-2">
          <Col>
            <h4 className="mb-3">My Quotes</h4>
          </Col>
        </Row>
        <Row className="px-2 pb-3">
          {/*<Col xs={12} md={3} xxl={2} className='pb-3'>
                    <Card>
                        <Card.Body>
                            <Row className='py-2'>
                                <Form.Group id="sortByFilter">
                                    <Form.Label htmlFor="sortByControl">
                                        Sort By:
                                    </Form.Label>
                                    <Form.Select id="sortByControl">
                                        <option value="newest">Newest Quote</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>*/}
          {loading ? (
            <Col xs={12} md={9} xxl={10}>
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : (
            <Col className="pb-3">
              {myProjectsQuotesData && myProjectsQuotesData.length === 0 && (
                <Card className="px-3 py-3">
                  <Col>
                    <Alert
                      className={styles.instructionBackgroundColor + ' my-2'}
                    >
                      <Row className="py-2">
                        <Col xs={2} lg={2} className="text-end">
                          <div className="mb-2">
                            <Image src={iconInstruction} />
                          </div>
                        </Col>
                        <Col xs={10} lg={9}>
                          <p style={{ fontWeight: 'bold' }}>What's next?</p>
                          <p>
                            You do not have quotes submitted to any project yet.
                          </p>
                          <p>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 1.
                              </span>{' '}
                              Browse projects.
                            </div>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 2.
                              </span>{' '}
                              Find and view details of the project you are
                              interested in working on.
                            </div>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 3.
                              </span>{' '}
                              Submit a quote to get started.
                            </div>
                          </p>
                          <p className="mb-0">
                            If you have any questions in the meantime, please
                            reach out to{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              hello@packagemaven.com
                            </span>
                            !
                          </p>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Card>
              )}
              {myProjectsQuotesData &&
                myProjectsQuotesData.map(data => {
                  let projectProgressSupplierUrl = `${PageRoutes.PROJECT_PROGRESS_SUPPLIER}/${data.project.id}?quoteId=${data.quote.id}`

                  if (data.quoteAdjusted) {
                    projectProgressSupplierUrl += `&adjustedQuoteId=${data.quoteAdjusted.id}`
                  }

                  return (
                    <Card
                      className="mb-3"
                      key={myProjectsQuotesData.indexOf(data)}
                    >
                      <Row>
                        <Col>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <Row>
                                <Col xs={6} lg={2}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Project
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      <Nav.Link
                                        as={Link}
                                        to={`${PageRoutes.PROJECT_DETAILS}/${data?.project?.id}`}
                                        style={{ color: 'rgb(13, 110, 253)' }}
                                      >
                                        {data?.project?.name}
                                      </Nav.Link>
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} lg={3}>
                                  <Stack
                                    direction="vertical"
                                    gap={
                                      data?.quote?.status ===
                                        constants.STATUS_QUOTE_SELECTED
                                        ? 1
                                        : 2
                                    }
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Status
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.quote?.status ===
                                        constants.STATUS_QUOTE_SELECTED ? (
                                        <Button
                                          variant="success"
                                          size="sm"
                                          className="text-start"
                                        >
                                          <Nav.Link
                                            as={Link}
                                            to={projectProgressSupplierUrl}
                                          >
                                            {getLatestStatusLabel(
                                              data?.project,
                                              engagedQuoteStatusHistory
                                            )}
                                          </Nav.Link>
                                        </Button>
                                      ) : data?.project?.status !==
                                        constants.STATUS_PROJECT_ACCEPTING_QUOTES ? (
                                        data?.project?.status
                                      ) : (
                                        data?.quote?.status
                                      )}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} lg={2}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Brand
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.project?.brand}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} lg={2}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Estimated quote
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.quote?.estimatedQuote
                                        ? '$' +
                                        parseFloat(
                                          data?.quote?.estimatedQuote
                                        ).toFixed(2)
                                        : 'N/A'}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} lg={1}></Col>
                                <Col xs={6} lg={2}>
                                  <Stack
                                    direction="vertical"
                                    gap={1}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Quote
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="auto"
                                        delay={{ show: 250, hide: 1000 }}
                                        overlay={
                                          <Popover
                                            id={`popover-quote-info-${data?.id}`}
                                            style={{ minWidth: '400px' }}
                                          >
                                            <Popover.Header as="h3">
                                              Quote info
                                            </Popover.Header>
                                            <Popover.Body>
                                              <div>
                                                <li>
                                                  <span>
                                                    Quote draft is not visible
                                                    to the buyer until
                                                    submitted.
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>
                                                    Quote edit is available
                                                    until the buyer selects one.
                                                  </span>
                                                </li>
                                              </div>
                                              <div className="mt-3">
                                                We recommend not to edit quote
                                                once submitted unless the buyer
                                                requests.
                                              </div>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <Image
                                          style={{
                                            height: '14px',
                                            marginBottom: '2px'
                                          }}
                                          className="ps-1"
                                          src={iconInfo}
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {(data?.quote?.status ===
                                        constants.STATUS_QUOTE_DRAFT &&
                                        data?.project?.status ===
                                        constants.STATUS_PROJECT_ACCEPTING_QUOTES) ||
                                        (data?.quote?.status ===
                                          constants.STATUS_QUOTE_SUBMITTED &&
                                          data?.project?.status ===
                                          constants.STATUS_PROJECT_ACCEPTING_QUOTES) ? ( // || data?.quote?.status === constants.STATUS_QUOTE_INIT_ASSESSMENT) ? (
                                        <Button
                                          className={
                                            styles.buttonOutline + ' py-1'
                                          }
                                          size="sm"
                                          onClick={handleQuoteEdit}
                                          data-project-id={data?.project?.id}
                                        >
                                          Edit
                                        </Button>
                                      ) : (
                                        <Button
                                          className={
                                            styles.buttonOutline +
                                            ' py-1 ms-auto'
                                          }
                                          size="sm"
                                        >
                                          <Nav.Link
                                            as={Link}
                                            to={
                                              PageRoutes.QUOTE_DETAILS +
                                              '/' +
                                              data?.quote.id
                                            }
                                          >
                                            Details
                                          </Nav.Link>
                                        </Button>
                                      )}
                                    </div>
                                  </Stack>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}
