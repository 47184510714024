import styles from '../styles/styles.module.css'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner, Alert, Image } from 'react-bootstrap'
import ProjectCard from '../components/ProjectCard'
import { getAllProjects } from '../services/supplierServices'
import * as constants from '../constants'
import iconInstruction from '../images/icons/instruction.png'

export const Projects = () => {
  const [allProjectsData, setAllProjectsData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function projectsFetchData() {
      try {
        const response = await getAllProjects(
          constants.STATUS_PROJECT_ACCEPTING_QUOTES
        )
        setAllProjectsData(response)
      } catch (error) {
        console.log(error)
      }
    }
    projectsFetchData()
  }, [])

  useEffect(() => {
    if (!allProjectsData) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [allProjectsData])

  return (
    <>
      <Container fluid={true}>
        <Row className="px-2 py-3">
          {/*<Col xs={12} md={3} xxl={2} className='pb-3'>
                    <Card>
                        <Card.Body>
                            <Row className='py-2'>
                                <Form.Group id="statusFilter">
                                    <Form.Label htmlFor="statusFilterControl">
                                        Status:
                                    </Form.Label>
                                    <Form.Select id="statusFilterControl">
                                        <option value="open">Open</option>
                                        <option value="quotedByMe">Quoted by Me</option>
                                        <option value="apptBooked">Selected</option>
                                        <option value="treatmentCompleted">Complete</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className='py-2'>
                                <Form.Group id="sortByFilter">
                                    <Form.Label htmlFor="sortByControl">
                                        Sort by:
                                    </Form.Label>
                                    <Form.Select id="sortByControl">
                                        <option value="newest">Newest</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className='py-2'>
                                <Form.Group id="baseTypeFilter">
                                    <Form.Label htmlFor="baseTypeControl">
                                        Base type:
                                    </Form.Label>
                                    <Form.Select id="sortByControl">
                                        <option value="Bottle">Bottle</option>
                                        <option value="Jar">Jar</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>*/}
          {loading ? (
            <Col xs={12}>
              {/*} md={9} xxl={10}>*/}
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : allProjectsData && allProjectsData.length > 0 ? (
            <Col xs={12}>
              {/*} md={9} xxl={10}>*/}
              <Row className="pb-3">
                {allProjectsData?.map(data => (
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    xxl={3}
                    className="pb-3"
                    key={allProjectsData.indexOf(data)}
                  >
                    <ProjectCard data={data} />
                  </Col>
                ))}
              </Row>
            </Col>
          ) : (
            <>
              <Col>
                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                  <Row className="py-2">
                    <Col xs={2} lg={2} className="text-end">
                      <div className="mb-2">
                        <Image src={iconInstruction} />
                      </div>
                    </Col>
                    <Col xs={10} lg={9}>
                      <p style={{ fontWeight: 'bold' }}>
                        Welcome to Package Maven.
                      </p>
                      <p>
                        Brands are in the process of creating projects. In the
                        meantime, here is an overview of how Package Maven
                        works.
                      </p>
                      <p>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 1.</span>{' '}
                          Brands create new projects.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 2.</span>{' '}
                          Package Maven expert will review the submitted project
                          and provide suggestions.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 3.</span>{' '}
                          Suppliers browse projects and submit quotes.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 4.</span>{' '}
                          Brands select suppliers to engage with.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 5.</span>{' '}
                          Brands and suppliers engage in non-deco sample step.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 6.</span>{' '}
                          Brands and suppliers engage in design review step.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 7.</span>{' '}
                          Brands and suppliers engage in pre-production sample
                          step.
                        </div>
                        <div className="mb-2">
                          <span style={{ fontWeight: 'bold' }}>Step 8.</span>{' '}
                          Brands make purchase order.
                        </div>
                      </p>
                      <p className="mb-0">
                        If you have any questions in the meantime, please reach
                        out to{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          hello@packagemaven.com
                        </span>
                        !
                      </p>
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  )
}
