import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { getProjectData } from '../services/buyerServices'
import { ProjectDetails } from '../components/ProjectDetails'

export const ProjectDetailsPage = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState()
  const [searchParams] = useSearchParams()
  const allowQuoteSubmit = searchParams.get('allowQuoteSubmit')

  useEffect(() => {
    async function fetchProject(projectId) {
      try {
        const response = await getProjectData(projectId)
        setProject(response)
      } catch (error) {
        console.log(error)
      }
    }

    fetchProject(projectId)
  }, [projectId, setProject])

  return project !== undefined ? (
    <ProjectDetails project={project} allowQuoteSubmit={allowQuoteSubmit} />
  ) : null
}
