import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import {
  Modal,
  Stack,
  Dropdown,
  DropdownButton,
  Button,
  Container,
  DropdownToggle,
  Image
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { signOut } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { BuyerProfile } from './BuyerProfile'
import { SupplierProfile } from './SupplierProfile'
import { PageRoutes } from '../constants'
import styles from '../styles/styles.module.css'
import { RxHamburgerMenu } from 'react-icons/rx'
import { GrProjects } from 'react-icons/gr'
import { FaList } from 'react-icons/fa'
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia'
import { HiOutlineAdjustments } from 'react-icons/hi'
import { RiMailSendLine } from 'react-icons/ri'
import imgLogo from '../images/logo_black.png'
import { UpdatePassword } from './UpdatePassword'
import useUser from '../hooks/useUser'
import { useDemoRequests } from '../hooks/useDemoRequests'
import { DemoRequestStatus } from '../types'

export const SideNavBar = props => {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const userData = useUser(user?.email)
  const [showProfile, setShowProfile] = useState(false)
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)
  const { demoRequests: pendingDemoRequests } = useDemoRequests(
    DemoRequestStatus.Pending
  )

  // sidebar
  //const [toggled, setToggled] = useState(false);
  //const [broken, setBroken] = useState(false);
  const [collapsed, setCollapsed] = useState(false)
  const collapsedWidth = props?.collapsedWidth

  useEffect(() => {
    if (userData) {
      if (!userData?.isProfileComplete) {
        navigate('/registerProfile')
      }
    }
  }, [userData, navigate])

  const handleLogout = async e => {
    e.preventDefault()
    try {
      signOut(auth)
        .then(() => {
          navigate('/')
          console.log('Signed out successfully.')
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <div className="loading-component">
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        collapsedWidth={collapsedWidth + 'px'}
        style={{ height: '100%' }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Menu
            className={styles.sideMenuBarBackgroundColor}
            menuItemStyles={{
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: '#DDE7FF !important',
                  color: '#3F46D6 !important'
                }
              }
            }}
            style={{ height: '100%' }}
          >
            {collapsed ? (
              <div className="text-center">
                <Button
                  onClick={() => {
                    setCollapsed(!collapsed)
                    props.sendToParentCollapseChanged()
                  }}
                  className="ms-2"
                  variant="transparent"
                >
                  <RxHamburgerMenu size={20} />
                </Button>
              </div>
            ) : (
              <Stack
                direction="horizontal"
                className="d-flex align-items-center"
              >
                <MenuItem component={<Link as={Link} to={PageRoutes.HOME} />}>
                  <Image src={imgLogo} fluid />
                </MenuItem>
                <div className="text-end">
                  <Button
                    onClick={() => {
                      setCollapsed(!collapsed)
                      props.sendToParentCollapseChanged()
                    }}
                    variant="transparent"
                  >
                    <RxHamburgerMenu size={20} />
                  </Button>
                </div>
              </Stack>
            )}
            <br />
            {user && (userData?.role?.supplier || userData?.role?.admin) && (
              <MenuItem
                icon={<GrProjects />}
                component={<Link as={Link} to={PageRoutes.BROWSE_PROJECTS} />}
              >
                Browse Projects
              </MenuItem>
            )}
            {user && (userData?.role?.buyer || userData?.role?.admin) && (
              <MenuItem
                icon={<FaList />}
                component={<Link as={Link} to={PageRoutes.MY_PROJECTS} />}
              >
                My Projects
              </MenuItem>
            )}
            {user && (userData?.role?.supplier || userData?.role?.admin) && (
              <MenuItem
                icon={<LiaFileInvoiceDollarSolid size={20} />}
                component={<Link as={Link} to={PageRoutes.MY_QUOTES} />}
              >
                My Quotes
              </MenuItem>
            )}
            {user && userData?.role?.admin && (
              <>
                <MenuItem
                  icon={<HiOutlineAdjustments size={20} />}
                  component={<Link as={Link} to={PageRoutes.INIT_ASSESSMENT} />}
                >
                  Initial Assessment
                </MenuItem>
                <MenuItem
                  icon={<RiMailSendLine size={20} />}
                  component={<Link as={Link} to={PageRoutes.DEMO_REQUESTS} />}
                >
                  Demo Requests{' '}
                  {pendingDemoRequests.length > 0 ? (
                    <span
                      style={{ color: 'red' }}
                    >{`(${pendingDemoRequests.length})`}</span>
                  ) : undefined}
                </MenuItem>
              </>
            )}
            {!user && (
              <MenuItem component={<Link as={Link} to={'/login'} />}>
                Login
              </MenuItem>
            )}
            {!user && (
              <MenuItem component={<Link as={Link} to={'/register'} />}>
                Register
              </MenuItem>
            )}
          </Menu>
          {user && userData && (
            <div className={styles.sideMenuBarBackgroundColor + ' py-3 px-3'}>
              <DropdownButton
                id="profile-dropdown"
                title={
                  userData?.firstName?.charAt(0) + userData?.lastName?.charAt(0)
                }
                variant="outline-secondary"
                drop="up"
              >
                <Dropdown.Item as="button" onClick={() => setShowProfile(true)}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setShowUpdatePassword(true)}
                >
                  Update password
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogout}>
                  Logout
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )}
        </div>
      </Sidebar>

      <Modal
        show={showProfile}
        fullscreen={true}
        onHide={() => setShowProfile(false)}
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userData?.role?.buyer || userData?.role?.admin ? (
            <BuyerProfile sendToParent={() => setShowProfile(false)} />
          ) : (
            <SupplierProfile sendToParent={() => setShowProfile(false)} />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showUpdatePassword}
        onHide={() => setShowUpdatePassword(false)}
        size="lg"
        centered
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 py-0">
          <UpdatePassword />
        </Modal.Body>
      </Modal>
    </>
  )
}
