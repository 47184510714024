import styles from '../styles/styles.module.css'
import { Container, Row, Col, Stack } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import QuoteCard from '../components/QuoteCard'

export const QuoteDetails = () => {
  const { quoteId } = useParams()

  return (
    <Container fluid={true} className="px-3 py-3">
      <Row className="px-2 pb-2">
        <Col>
          <h4 className="mb-3">Quote Details</h4>
        </Col>
      </Row>
      <Row className="px-2">
        <QuoteCard quoteId={quoteId} />
      </Row>
      <Row className="px-2 py-3">
        <Col>
          <Stack direction="vertical" gap={3}>
            <div className={styles.cardListItemLabel}>Terms and Conditions</div>
            <div className={styles.cardListItemValueTC + ' ms-auto'}>
              All prices are preliminary until final artwork files and color
              targets are received, and samples are made. Final Prices will be
              confirmed upon pre-production sample approval. Prices contained in
              this quotation are valid for 60 days from the quotation date.
              Package Maven recommends that all packs, components, materials and
              decorations are tested to ensure product compatibility,
              functionally and suitability of use. The conduct of these tests is
              the sole responsibility of the client. All modifications made at
              the client's request will be charged in full. All mold and tooling
              costs will be charged in full in the event of order cancellation.
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}
