import { Form } from 'react-bootstrap'
import styles from '../styles/styles.module.css'

const LabeledTextarea = ({
  controlId,
  label,
  value,
  onChange,
  required = false,
  numberOfRows
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={styles.detailItemLabel}>
        {label}
        {required && (
          <span className="ms-1" style={{ color: 'red' }}>
            *
          </span>
        )}
      </Form.Label>
      <Form.Control
        as="textarea"
        onChange={onChange}
        rows={numberOfRows}
        value={value}
      />
    </Form.Group>
  )
}

export default LabeledTextarea
