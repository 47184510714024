import styles from '../styles/styles.module.css'
import { Row, Col, Nav, Stack, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageRoutes } from '../constants'
import imgLogo from '../images/logo_white.png'

export const Footer = () => {
  return (
    <>
      <Row
        fluid="true"
        className={styles.footerBackground + ' px-4 py-5 mx-0'}
        style={{ left: 0, right: 0, bottom: 0 }}
      >
        <Col lg={{ span: 10, offset: 1 }}>
          <Row className="pb-5">
            <div>
              <Image src={imgLogo} />
            </div>
          </Row>
          <Row className="pb-2">
            <Col lg={6} className="pb-3">
              <Stack direction="vertical" gap={1}>
                <span className={styles.footerSectionHeader}>About</span>
                <Nav.Link as={Link} to={PageRoutes.PRIVACY_POLICY}>
                  <span className={styles.footerContent}>PRIVACY POLICY</span>
                </Nav.Link>
                {process.env.REACT_APP_SERVER_NEWS_WORDPRESS_API_ENDPOINT && (
                  <Nav.Link as={Link} to={PageRoutes.NEWS}>
                    <span className={styles.footerContent}>NEWSROOM</span>
                  </Nav.Link>
                )}
              </Stack>
            </Col>
            <Col lg={6} className="pb-3">
              <Stack direction="vertical" gap={1}>
                <span className={styles.footerSectionHeader}>Contact</span>
                <Nav.Link href="mailto:hello@packagemaven.com" target="_blank">
                  <span className={styles.footerContent}>
                    HELLO@PACKAGEMAVEN.COM
                  </span>
                </Nav.Link>
                <Nav.Link href={PageRoutes.DEMO} target="_blank">
                  <span className={styles.footerContent}>BOOK DEMO</span>
                </Nav.Link>
              </Stack>
            </Col>
            {/*<Col lg={4} className="pb-3">
                        <Stack direction="vertical" gap={1}>
                            <span className={styles.footerSectionHeader}>Social Media</span>
                            <Stack direction="horizontal" gap={2}>
                                <Nav.Link target="_blank" href="https://www.instagram.com/by_dental_square/" >
                                    <Image src={iconInstagram}></Image>                                    
                                </Nav.Link>                                    
                            </Stack>
                        </Stack>
                    </Col>*/}
          </Row>
          <Row className="pb-2">
            <div>
              <span className={styles.footerCopyright}>
                &copy; Package Maven Inc. All rights reserved.
              </span>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  )
}
