import styles from '../styles/styles.module.css'
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  Alert,
  Image,
  ListGroup
} from 'react-bootstrap'
import QuoteCardHorizontal from './QuoteCardHorizontal'
import iconInstruction from '../images/icons/instruction.png'
import moment from 'moment'

export const StepBuyerSelectSupplierQuote = props => {
  const projectData = props?.projectData
  const quotesData = props?.quotes //stateObj?.quotes;

  const handleQuoteSelected = e => {
    props.sendToParentQuoteSelected()
  }

  return (
    <Container fluid={true}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Stack direction="horizontal" gap={5}>
              <h4>Select supplier quote</h4>
            </Stack>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="px-2 py-2">
              <Col xl={12} className='pb-3'>
                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                  <Row className="justify-content-md-center">
                    <Col>
                      <ListGroup horizontal>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>
                              {projectData?.name}
                            </div>                            
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>Brand</div>
                            <hr className="my-2" />
                            <div className={styles.cardListItemValue}>{projectData?.brand}</div>
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>Target unit cost</div>
                            <hr className="my-2" />
                            <div className={styles.cardListItemValue}>{'$' + projectData?.targetUnitCost}</div>
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>Initial order quantity</div>
                            <hr className="my-2" />
                            <div className={styles.cardListItemValue}>{projectData?.initOrderQuantity}</div>
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>Required date</div>
                            <hr className="my-2" />
                            <div className={styles.cardListItemValue}>
                              {moment
                                .unix(projectData?.deliveryDate?.seconds)
                                .format('MM/DD/YYYY')}
                            </div>
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                          <Stack direction="vertical" gap={2}>
                            <div className={styles.cardListItemLabel}>Receiving location</div>
                            <hr className="my-2" />
                            <div className={styles.cardListItemValue}>{projectData?.receivingLocation}</div>
                          </Stack>
                        </ListGroup.Item>
                        <ListGroup.Item className={styles.instructionBackgroundColor + " text-center align-self-center"} style={{ width: '15%', borderWidth: '0px' }}>
                        </ListGroup.Item>
                      </ListGroup>                    
                    </Col>
                  </Row>
                </Alert>
              </Col>
              {quotesData?.length === 0 && (
                <Col xl={12} className="pb-3">
                  <Alert
                    className={styles.instructionBackgroundColor + ' my-2'}
                  >
                    <Row className='className="justify-content-md-center py-2'>
                      <Col xs={2} xl={3} className="text-end">
                        <div className="mb-2">
                          <Image src={iconInstruction} />
                        </div>
                      </Col>
                      <Col xs={10} xl={7}>
                        <p style={{ fontWeight: 'bold' }}>What's next?</p>
                        <p>Your project is accepting quotes from suppliers.</p>
                        <p>
                          <span style={{ fontWeight: 'bold' }}>Step 1.</span>{' '}
                          Suppliers are currently reviewing your project.
                          <br />
                          <span style={{ fontWeight: 'bold' }}>
                            Step 2.
                          </span>{' '}
                          Suppliers will prepare and submit quotes.
                          <br />
                          <span style={{ fontWeight: 'bold' }}>
                            Step 3.
                          </span>{' '}
                          Submitted quotes will be displayed here for you to
                          compare and select one to proceed.
                        </p>
                        <p className="mb-0">
                          If you have any questions in the meantime, please
                          reach out to{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            hello@packagemaven.com
                          </span>
                          !
                        </p>
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              )}
              {quotesData?.map(data => (
                <Col xl={12} className="pb-2" key={quotesData?.indexOf(data)}>
                  <QuoteCardHorizontal
                    projectId={projectData?.id}
                    quoteData={data}
                    sendToParentQuoteSelected={handleQuoteSelected}
                  />
                </Col>
              ))}
            </Row>
            <Row className="px-2 pt-3 pb-2">
              <Col>
                <Stack direction="vertical" gap={3}>
                  <div className={styles.cardListItemLabel}>
                    Terms and Conditions
                  </div>
                  <div className={styles.cardListItemValueTC + ' ms-auto'}>
                    All prices are preliminary until final artwork files and
                    color targets are received, and samples are made. Final
                    Prices will be confirmed upon pre-production sample
                    approval. Prices contained in this quotation are valid for
                    60 days from the quotation date. Package Maven recommends
                    that all packs, components, materials and decorations are
                    tested to ensure product compatibility, functionally and
                    suitability of use. The conduct of these tests is the sole
                    responsibility of the client. All modifications made at the
                    client's request will be charged in full. All mold and
                    tooling costs will be charged in full in the event of order
                    cancellation.
                  </div>
                </Stack>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
}
