import { Stack } from 'react-bootstrap'
import styles from '../styles/styles.module.css'

const Divider = ({ label }) => {
  return (
    <Stack direction="horizontal">
      {label && <span className={styles.detailItemLabel}>{label}</span>}
      <div className="ps-2" style={{ width: '100%' }}>
        <hr />
      </div>
    </Stack>
  )
}

export default Divider
