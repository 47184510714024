import { Form } from 'react-bootstrap'
import styles from '../styles/styles.module.css'

const LabeledInputSelect = ({
  controlId,
  label,
  subLabel,
  value,
  options,
  onChange,
  required = false
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={styles.detailItemLabel}>
        {label}
        {required && (
          <span className="ms-1" style={{ color: 'red' }}>
            *
          </span>
        )}
        {subLabel && <span style={{ fontStyle: 'italic' }}>{subLabel}</span>}
      </Form.Label>
      <Form.Select onChange={onChange} value={value} required={required}>
        <option value={''}>Select</option>
        {options.map(option => (
          <option>{option}</option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default LabeledInputSelect
