import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Card
} from 'react-bootstrap'
import { updateSupplierProfile } from '../services/supplierServices'
import { auth } from '../firebase'
import imgLogo from '../images/logo_white.png'
import useUser from '../hooks/useUser'
import { useToast } from '../ToastContext';

export const SupplierProfile = props => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const supplier = useUser(auth?.currentUser?.email)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  //const [officeAddress1, setOfficeAddress1] = useState("");
  //const [officeAddress2, setOfficeAddress2] = useState("");
  //const [officeCity, setOfficeCity] = useState("");
  //const [officeState, setOfficeState] = useState("");
  //const [officeZip, setOfficeZip] = useState("");

  useEffect(() => {
    if (supplier) {
      setFirstName(supplier?.firstName ? supplier?.firstName : '')
      setLastName(supplier?.lastName ? supplier?.lastName : '')
      setCompany(supplier?.company ? supplier?.company : '')
      setTitle(supplier?.title ? supplier?.title : '')
      if (supplier?.phone && supplier?.phone.length > 0) {
        setPhone(supplier?.phone)
      }
      setLocation(supplier?.location ? supplier?.location : '')
    }
  }, [supplier])

  const onSubmit = async e => {
    e.preventDefault()
    try {
      updateSupplierProfile(
        firstName,
        lastName,
        phone,
        company,
        title,
        location
      ).then(() => {
        addToast('Profile saved successfully', 'success')

        if (props?.navigateTo) {
          navigate(props?.navigateTo)
          window?.location?.reload()
        } else {
          props.sendToParent()
        }
      })
    } catch {
      addToast('Submitting supplier profile failed, please try again.', 'danger')
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={4} className="px-3">
            <Card style={{ backgroundColor: '#3F46D6', height: '100%' }}>
              <Card.Body className="px-5">
                <p
                  className="py-5"
                  style={{ color: '#FFFFFF', fontSize: '40px' }}
                >
                  We solve all your packaging needs.
                </p>
                <p style={{ color: '#FFFFFF', fontSize: '16px' }}>
                  Our mission is to revolutionize the cosmetic packaging supply
                  chain by creating a transparent, agile, and high-performing
                  ecosystem that seamlessly connects Suppliers and Brands.
                </p>
              </Card.Body>
              <Card.Footer style={{ borderWidth: 0 }}>
                <Image src={imgLogo} fluid className="px-4 py-4" />
              </Card.Footer>
            </Card>
          </Col>
          <Col md={8} className="px-3">
            <Form onSubmit={onSubmit}>
              <Row className="my-5">
                <h4 className="mb-2">Supplier</h4>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="firstName.control">
                      <Form.Label className={styles.listItemLabel}>
                        First name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="lastName.control">
                      <Form.Label className={styles.listItemLabel}>
                        Last name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="email.control">
                      <Form.Label className={styles.listItemLabel}>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        value={auth?.currentUser?.email}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="phone.control">
                      <Form.Label className={styles.listItemLabel}>
                        Phone (optional)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <Row className="mb-5">
                <h4 className="mb-2">Company</h4>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="company.control">
                      <Form.Label className={styles.listItemLabel}>
                        Company name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        required
                        disabled={!supplier?.role?.admin}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="title.control">
                      <Form.Label className={styles.listItemLabel}>
                        Position title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="location.control">
                      <Form.Label className={styles.listItemLabel}>
                        Location (City, State, Country)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={location}
                        placeholder="City, State, Country"
                        onChange={e => setLocation(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <Row className="mb-5">
                <Row>
                  <div>
                    <Button type="submit" className={styles.button}>
                      <span className={styles.buttonLabel}>Submit</span>
                    </Button>
                  </div>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
