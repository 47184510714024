import styles from '../styles/styles.module.css'
import { Container, Row } from 'react-bootstrap'
// import the progress bar
import StepProgressBar from 'react-step-progress'
// import the stylesheet
import 'react-step-progress/dist/index.css'

export default function ProgressBarProject(props) {
  const currentStep = props?.currentStep

  function onFormSubmit() {
    // handle the submit logic here
    // This function will be executed at the last step
    // when the submit button (next button in the previous steps) is pressed
  }

  return (
    <Container className="px-0">
      <Row className="pt-2 text-center">
        <h3>Progress steps</h3>
      </Row>
      <Row>
        {currentStep === -1 ? (
          <Row className="px-3 py-3">
            <h6>
              Something went wrong. Please select your project again or contact
              Package Maven.
            </h6>
          </Row>
        ) : (
          <StepProgressBar
            startingStep={currentStep}
            onSubmit={onFormSubmit}
            labelClass={styles.stepProgressBarLabel}
            primaryBtnClass={styles.stepProgressBarButtonHidden} // hide 'next' and 'submit' buttons
            secondaryBtnClass={styles.stepProgressBarButtonHidden} // hide 'prev' button
            wrapperClass="px-0 py-4"
            steps={[
              {
                label: 'Project creation',
                name: 'Project creation',
                //subtitle: 'Your project has been created',
                content: <></>
              },
              {
                label: 'Initial assessment',
                name: 'Initial assessment',
                //subtitle: 'Project assessment completed by Package Maven',
                content: <></>
              },
              {
                label: 'Supplier selection',
                name: 'Supplier selection',
                content: <></>
              },
              {
                label: 'Non-deco samples',
                name: 'Non-deco samples',
                content: <></>
              },
              {
                label: 'Design review',
                name: 'Design review',
                content: <></>
              },
              {
                label: 'Pre-production samples',
                name: 'Pre-production samples',
                content: <></>
              },
              {
                label: 'Purchase order',
                name: 'Purchase order',
                content: <></>
              }
            ]}
          />
        )}
      </Row>
    </Container>
  )
}
