import { Nav, Stack } from 'react-bootstrap'
import { NewsPost } from '../types'
import '../styles/styles.module.css'
import { formatDate } from '../utils/date'
import { Link } from 'react-router-dom'
import { stripHtml } from '../utils/news'
import { PageRoutes } from '../constants'

const NewsPostCard = ({ post }: { post: NewsPost }) => {
  return (
    <Nav.Link as={Link} to={`${PageRoutes.NEWS}/${post.slug}`}>
      <Stack className="mb-3 mb-xl-5">
        <img
          style={{ borderRadius: 10, aspectRatio: 16 / 9, marginBottom: 15 }}
          src={post.imageUrl}
        />
        <h4 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div className="newsContentCard">{stripHtml(post.contentHtml)}</div>
        <span className="mt-3 fs-6 text-secondary">
          {formatDate(post.date)}
        </span>
      </Stack>
    </Nav.Link>
  )
}

export default NewsPostCard
