import DatePicker from 'react-date-picker'
import styles from '../styles/styles.module.css'
import { Form, Row } from 'react-bootstrap'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import '../styles/DatePicker.css'

const LabeledDatePicker = ({
  controlId,
  label,
  required = false,
  minDate = new Date(),
  value,
  onChange
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={styles.detailItemLabel}>
        {label}
        {required && (
          <span className="ms-1" style={{ color: 'red' }}>
            *
          </span>
        )}
      </Form.Label>
      <Row>
        <DatePicker
          onChange={onChange}
          value={value}
          minDate={minDate}
          required={required}
        />
      </Row>
    </Form.Group>
  )
}

export default LabeledDatePicker
