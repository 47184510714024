import { Timestamp } from 'firebase/firestore'

export type NewsPost = {
  id: string
  title: string
  contentHtml: string
  imageUrl?: string
  date: Date
  slug: string
}

export type DemoRequestForm = {
  firstName: string
  lastName: string
  email: string
  companyName: string
}

export type DemoRequest = DemoRequestForm & {
  id: string
  createdOn: Timestamp
  status: DemoRequestStatus
}

export enum DemoRequestStatus {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Completed = 'Completed'
}

export type User = {
  role: {
    admin: boolean
    supplier: boolean
    buyer: boolean
  }
}

export enum Courier {
  FedEx = 'FedEx',
  UPS = 'UPS',
  DHL = 'DHL'
}