import styles from '../styles/styles.module.css'
import { useState, useRef } from 'react'
import { Container, Row, Col, Alert, Button, Form } from 'react-bootstrap'
import { addProjectData, updateProjectData } from '../services/buyerServices'
import { sendNotification } from '../services/notificationServices'
import { useNavigate } from 'react-router-dom'
import * as constants from '../constants'
import { PageRoutes } from '../constants'
import useProjectForm from '../hooks/useProjectForm'
import { useToast } from '../ToastContext'

export const ProjectUpload = props => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const saveDraftBtnRef = useRef()
  const submitBtnRef = useRef()

  const [projectId, setProjectId] = useState('')
  const { form, buildProject, designFiles } = useProjectForm({})

  const onSaveDraft = async e => {
    e.preventDefault()
    if (saveDraftBtnRef.current) {
      saveDraftBtnRef.current.setAttribute('disabled', 'disabled')

      if (!projectId || projectId.length === 0) {
        // project has not been saved as draft
        try {
          const result = await addProjectData(
            buildProject(),
            designFiles,
            constants.STATUS_PROJECT_DRAFT
          )

          addToast('Project draft created successfully', 'success')
          setProjectId(result?.id)
        } catch (e) {
          addToast('Creating project draft failed, please try again.', 'danger')
          console.error(e)
        } finally {
          saveDraftBtnRef.current.removeAttribute('disabled')
        }
      } else {
        // project has been saved as draft
        try {
          updateProjectData(
            projectId,
            buildProject(),
            designFiles,
            constants.STATUS_PROJECT_DRAFT
          ).then(() => {
            addToast('Project draft updated successfully.', 'success')
            //setProjectId(result?.id);
          })
        } catch {
          addToast('Updating project draft failed, please try again.', 'danger')
        } finally {
          saveDraftBtnRef.current.removeAttribute('disabled')
        }
      }
    }
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute('disabled', 'disabled')

      if (!projectId || projectId.length === 0) {
        // project has not been saved as draft
        try {
          addProjectData(
            buildProject(),
            designFiles,
            constants.STATUS_PROJECT_INIT_ASSESSMENT
          ).then(result => {
            addToast('Project created successfully. Package Maven expert will run the initial assessment and provide feedback.', 'success')
            sendNotification(
              constants.NOTI_TYPE_BUYER_PROJECT_CREATED,
              result.id,
              null
            )
            props.sendToParentProjectCreated()
            setProjectId(result.id)
            navigate(PageRoutes.MY_PROJECTS)
          })
        } catch {
          addToast('Creating project failed, please try again.', 'danger')
          submitBtnRef.current.removeAttribute('disabled')
        } finally {
          // button remain disabled
        }
      } else {
        // project has been saved as draft
        try {
          updateProjectData(
            projectId,
            buildProject(),
            designFiles,
            constants.STATUS_PROJECT_INIT_ASSESSMENT
          ).then(() => {
            addToast('Project updated successfully. Package Maven expert will run the initial assessment and provide feedback.', 'success')
            sendNotification(
              constants.NOTI_TYPE_BUYER_PROJECT_CREATED,
              projectId,
              null
            )
            props.sendToParentProjectCreated()
            navigate(PageRoutes.MY_PROJECTS)
          })
        } catch {
          addToast('Creating project failed, please try again.', 'danger')

          submitBtnRef.current.removeAttribute('disabled')
        } finally {
          // button remain disabled
        }
      }
    }
  }

  return (
    <>
      <Container fluid={true}>
        <Form onSubmit={onSubmit}>
          {form}
          <Row className="px-3 py-3">
            <Col xs={5} md={4} xl={3} className="px-0">
              <div className="d-grid gap-2">
                <Button
                  variant="outline-secondary"
                  className="py-2"
                  size="sm"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              md={{ span: 4, offset: 4 }}
              xl={{ span: 3, offset: 6 }}
              className="px-0"
            >
              <div className="d-grid gap-2">
                <Button
                  ref={saveDraftBtnRef}
                  onClick={onSaveDraft}
                  className={styles.buttonOutline + ' py-2'}
                  size="sm"
                >
                  Save draft
                </Button>
                <Button
                  ref={submitBtnRef}
                  type="submit"
                  className={styles.button + ' py-2'}
                  size="sm"
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
