import { useEffect, useState } from 'react'
import { getUser } from '../services/userServices'
import { User } from '../types'

export default function useUser(email: string | undefined | null) {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await getUser(email)
        setUser(response as User)
      } catch (error) {
        console.log(error)
      }
    }

    if (email) {
      fetchUser()
    }
  }, [email])

  return user
}
