import { useMemo } from 'react'
import { DemoRequest, DemoRequestStatus } from '../types'
import { fetchDemoRequests } from '../services/demoService'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import useUser from './useUser'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'

export const useDemoRequests = (status?: DemoRequestStatus) => {
  const [user] = useAuthState(auth)
  const userData = useUser(user?.email)
  const queryClient = useQueryClient()

  const { data, isLoading, error }: UseQueryResult<DemoRequest[], Error> =
    useQuery({
      queryKey: ['demoRequests'],
      queryFn: fetchDemoRequests,
      enabled: !!userData?.role?.admin
    })

  const filteredDemoRequests = useMemo(() => {
    return status === undefined
      ? data || []
      : (data || []).filter(request => request.status === status)
  }, [data, status])

  const invalidateDemoRequests = () => {
    queryClient.invalidateQueries({ queryKey: ['demoRequests'] })
  }

  return {
    demoRequests: filteredDemoRequests,
    loading: isLoading,
    error:
      error?.message ||
      (userData && !userData.role?.admin ? 'Not Accessible' : undefined),
    invalidateDemoRequests
  }
}
