import styles from '../styles/styles.module.css'
import { useState, useEffect, useRef } from 'react'
import { auth } from '../firebase'
import {
  Row,
  Col,
  Stack,
  Button,
  InputGroup,
  Form,
  Alert
} from 'react-bootstrap'
import { submitQuote, createQuote } from '../services/supplierServices'
import { sendNotification } from '../services/notificationServices'
import { getFile } from '../services/fileStorageServices'
import * as constants from '../constants'
import { EditableTable } from './EditableTable'
import { useToast } from '../ToastContext'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export const QuoteSubmitForm = props => {
  const { addToast } = useToast()
  const projectId = props?.projectId
  const acceptLargerQuantityQuotes = props?.acceptLargerQuantityQuotes
  const initOrderQuantity = parseFloat(props?.initOrderQuantity)
  const designFlexibility = props?.designFlexibility
  const prevQuote = props?.prevSubmittedQuote
  const iteration = props?.iteration

  const saveDraftBtnRef = useRef()
  const submitQuoteBtnRef = useRef()

  const [errorMsg, setErrorMsg] = useState([])

  const [isExactDesign, setIsExactDesign] = useState(
    prevQuote?.isExactDesign ? prevQuote?.isExactDesign : ''
  )
  const [productionTimeline, setProductionTimeline] = useState(
    prevQuote?.productionTimeline ? prevQuote?.productionTimeline : ''
  )
  const [shippingTimeline, setShippingTimeline] = useState(
    prevQuote?.shippingTimeline ? prevQuote?.shippingTimeline : ''
  )
  const [moq, setMoq] = useState(prevQuote?.moq ? prevQuote?.moq : '')

  const designTechDrawingFileRefData = prevQuote?.designTechDrawingFileRefData
  const componentPictureFileRefData = prevQuote?.componentPictureFileRefData
  const [designTechDrawingFiles, setDesignTechDrawingFiles] = useState([])
  const [componentPictureFiles, setComponentPictureFiles] = useState([])

  const [unitCost, setUnitCost] = useState(prevQuote?.unitCost ? prevQuote?.unitCost : [])
  const [oneTimeCost, setOneTimeCost] = useState(prevQuote?.oneTimeCost ? prevQuote?.oneTimeCost : [])

  const [shippingLocation, setShippingLocation] = useState(
    prevQuote?.shippingLocation ? prevQuote?.shippingLocation : ''
  )

  const [estQuoteTotal, setEstQuoteTotal] = useState(getQuoteTotal())
  //const [estQuoteStateChanged, setEstQuoteStateChanged] = useState(false);

  const [expiresIn, setExpiresIn] = useState(
    prevQuote?.expiresIn ? prevQuote?.expiresIn : '60'
  )
  const [comments, setComments] = useState(
    prevQuote?.comments ? prevQuote?.comments : ''
  )

  useEffect(() => {
    async function filesFetchData() {
      try {
        if (designTechDrawingFileRefData) {
          var files = []
          for (const data of designTechDrawingFileRefData) {
            const response = await getFile(data.blobName)
            var base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            files.push(`data:image/png;base64,${base64String}`)
          }
          setDesignTechDrawingFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    filesFetchData()
  }, [])

  useEffect(() => {
    async function filesFetchData() {
      try {
        if (componentPictureFileRefData) {
          var files = []
          for (const data of componentPictureFileRefData) {
            const response = await getFile(data.blobName)
            var base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            files.push(`data:image/png;base64,${base64String}`)
          }
          setComponentPictureFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    filesFetchData()
  }, [])

  const onSubmitQuote = async e => {
    e.preventDefault()
    if (submitQuoteBtnRef.current) {
      submitQuoteBtnRef.current.setAttribute('disabled', 'disabled')

      if (isQuoteEligible()) {
        try {
          if (iteration) {
            // creates new quote record for adjustment iteration steps
            const adjustQuoteId = await createQuote(
              projectId,
              auth?.currentUser?.email,
              isExactDesign,
              productionTimeline,
              shippingTimeline,
              shippingLocation,
              designTechDrawingFiles,
              componentPictureFiles,
              moq,
              unitCost,
              oneTimeCost,
              getQuoteTotal(),
              expiresIn,
              comments,
              constants.STATUS_QUOTE_ADJUSTED
            )
            await sendNotification(
              constants.NOTI_TYPE_BUYER_QUOTE_ADJUST_SUBMITTED,
              projectId,
              adjustQuoteId
            )
            props.sendToParentAdjustedQuoteSubmitted()
            addToast('Adjusted quote submission successful.', 'success')
          } else {
            await submitQuote(
              projectId,
              auth?.currentUser?.email,
              isExactDesign,
              productionTimeline,
              shippingTimeline,
              shippingLocation,
              designTechDrawingFiles,
              componentPictureFiles,
              moq,
              unitCost,
              oneTimeCost,
              getQuoteTotal(),
              expiresIn,
              comments,
              constants.STATUS_QUOTE_SUBMITTED
            )
            await sendNotification(
              constants.NOTI_TYPE_BUYER_QUOTE_SUBMITTED,
              projectId,
              null
            )
            props.sendToParentQuoteSubmitted()
            addToast('Quote submission successful.', 'success')
          }
        } catch {
          addToast('Quote submission failed, please try again.', 'danger')
          submitQuoteBtnRef.current.removeAttribute('disabled')
        } finally {
          // button remain disabled
        }
      } else {
        submitQuoteBtnRef.current.removeAttribute('disabled')
      }
    }
  }

  const handleSaveQuoteAsDraft = e => {
    e.preventDefault()
    if (saveDraftBtnRef.current) {
      saveDraftBtnRef.current.setAttribute('disabled', 'disabled')

      try {
        submitQuote(
          projectId,
          auth?.currentUser?.email,
          isExactDesign,
          productionTimeline,
          shippingTimeline,
          shippingLocation,
          designTechDrawingFiles,
          componentPictureFiles,
          moq,
          unitCost,
          oneTimeCost,
          getQuoteTotal(),
          expiresIn,
          comments,
          constants.STATUS_QUOTE_DRAFT
        ).then(() => {
          props.sendToParentDraftQuoteSaved()
          addToast('Saving draft quote successful.', 'success')
        })
      } catch {
        addToast('Saving quote draft failed, please try again.', 'danger')
      } finally {
        saveDraftBtnRef.current.removeAttribute('disabled')
      }
    }
  }

  function isQuoteEligible() {
    let tempErrorMsg = ['Please correct the following:']
    let result = true
    if (acceptLargerQuantityQuotes === 'No' && moq > initOrderQuantity) {
      result = false
      tempErrorMsg.push(
        '- This project does not accept quotes with larger MOQ than the initial order qantity.'
      )
    }
    if (designFlexibility === 'No' && isExactDesign === 'No') {
      result = false
      tempErrorMsg.push('\n- This project only accepts exact design.')
    }
    if (unitCost.filter(uc => uc?.category === 'Base').length < 1) {
      result = false
      tempErrorMsg.push(
        '\n- Please insert Base component entry in unit cost.'
      )
    }
    if (
      unitCost.filter(
        uc =>
          uc?.category === 'Base' &&
          (uc?.description?.trim() === '' || uc?.price === 0)
      ).length > 0
    ) {
      result = false
      tempErrorMsg.push(
        '\n- Please enter Base component data in unit cost table: description and price.'
      )
    }
    if (unitCost.filter(uc => uc?.category === 'Closure').length < 1) {
      result = false
      tempErrorMsg.push(
        '\n- Please insert Closure component entry in unit cost table.'
      )
    }
    if (
      unitCost.filter(
        uc =>
          uc?.category === 'Closure' &&
          (uc?.description?.trim() === '' || uc?.price === 0)
      ).length > 0
    ) {
      result = false
      tempErrorMsg.push(
        '\n- Please enter Closure component data in unit cost table: description and price.'
      )
    }
    if (oneTimeCost.filter(uc => uc?.category === 'Final Delivery').length < 1) {
      result = false
      tempErrorMsg.push(
        '\n- Please insert Final Delivery entry in one-time cost table.'
      )
    }
    if (
      oneTimeCost.filter(
        uc =>
          uc?.category === 'Final Delivery' &&
          (uc?.description?.trim() === '' || uc?.price === 0)
      ).length > 0
    ) {
      result = false
      tempErrorMsg.push(
        '\n- Please enter Final Delivery data in one-time cost table: description and price.'
      )
    }

    if (!result) {
      setErrorMsg(tempErrorMsg)
    }
    return result
  }

  function getQuoteTotal() {
    let unitCostSum = 0
    for (const obj of unitCost) {
      if (obj?.price !== '') {
        unitCostSum = unitCostSum + parseFloat(obj?.price)
      }
    }

    let oneTimeCostSum = 0
    for (const obj of oneTimeCost) {
      if (obj?.price !== '') {
        oneTimeCostSum = oneTimeCostSum + parseFloat(obj?.price)
      }
    }

    return (
      unitCostSum * (moq ? parseInt(moq) : 0) +
      oneTimeCostSum
    ).toFixed(2)
  }

  useEffect(() => {
    setEstQuoteTotal(getQuoteTotal())
  }, [moq, unitCost, oneTimeCost])

  const handleUnitCostDataUpdate = editableTableData => {
    setUnitCost([...editableTableData])
  }

  const handleOneTimeCostDataUpdate = editableTableData => {
    setOneTimeCost([...editableTableData])
  }

  return (
    <Form onSubmit={onSubmitQuote}>
      <Row className="pb-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Requirements related</h6>
        </Row>
        <Col lg={6} xxl={4}>
          <Form.Label
            htmlFor="exactDesignControl"
            className={styles.detailItemLabel}
          >
            Exact design
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              onChange={e => setIsExactDesign(e.target.value)}
              value={isExactDesign}
              id="exactDesignControl"
              required
            >
              <option value={''}>Select</option>
              <option>Yes</option>
              <option>No</option>
            </Form.Select>
          </InputGroup>
        </Col>
        <Col lg={6} xxl={4}>
          <Form.Label htmlFor="moqControl" className={styles.detailItemLabel}>
            Min order quantity
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              id="moqControl"
              value={moq}
              onChange={e => setMoq(e.target.value)}
              required
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Timeline</h6>
        </Row>
        <Col lg={6} xxl={4}>
          <Form.Label
            htmlFor="productionTimelineControl"
            className={styles.detailItemLabel}
          >
            Production timeline
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              id="productionTimelineControl"
              value={productionTimeline}
              onChange={e => setProductionTimeline(e.target.value)}
              required
            />
            <InputGroup.Text className={styles.detailItemLabel}>
              Weeks
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col lg={6} xxl={4}>
          <Form.Label
            htmlFor="shippingTimelineControl"
            className={styles.detailItemLabel}
          >
            Shipping timeline
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              id="shippingTimelineControl"
              value={shippingTimeline}
              onChange={e => setShippingTimeline(e.target.value)}
              required
            />
            <InputGroup.Text className={styles.detailItemLabel}>
              Weeks
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Logistics</h6>
        </Row>
        <Col lg={6} xxl={4}>
          <Form.Label
            htmlFor="shippingLocationControl"
            className={styles.detailItemLabel}
          >
            Shipping location
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              id="shippingLocationControl"
              value={shippingLocation}
              placeholder="City, Country"
              onChange={e => setShippingLocation(e.target.value)}
              required
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Design / Technical drawings</h6>
        </Row>
        <Col>
          <Form.Label
            htmlFor="designTechDrawingFiles.control"
            className={styles.detailItemLabel}
          >
            Please upload 2D image file or PDF.
          </Form.Label>
          <FilePond
            files={designTechDrawingFiles}
            instantUpload={false}
            onupdatefiles={setDesignTechDrawingFiles}
            allowMultiple={true}
            maxFiles={20}
            dropOnPage={true}
            server={null}
            name="files"
            labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
            imagePreviewHeight={150}
            id="designTechDrawingFiles.control"
            acceptedFileTypes={[
              'image/jpg',
              'image/jpeg',
              'image/png',
              'application/pdf'
            ]}
            required
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Component pictures</h6>
        </Row>
        <Col>
          <Form.Label
            htmlFor="componentPictureFiles.control"
            className={styles.detailItemLabel}
          >
            Please upload 2D image file or PDF.
          </Form.Label>
          <FilePond
            files={componentPictureFiles}
            instantUpload={false}
            onupdatefiles={setComponentPictureFiles}
            allowMultiple={true}
            maxFiles={20}
            dropOnPage={true}
            server={null}
            name="files"
            labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
            imagePreviewHeight={150}
            id="componentPictureFiles.control"
            acceptedFileTypes={[
              'image/jpg',
              'image/jpeg',
              'image/png',
              'application/pdf'
            ]}
            required
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Unit cost</h6>
        </Row>
        <Col xs={12}>
          <EditableTable
            data={unitCost}
            type="unitCost"
            sendToParentTableData={handleUnitCostDataUpdate}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>One-time cost</h6>
        </Row>
        <Col xs={12}>
          <EditableTable
            data={oneTimeCost}
            type="oneTimeCost"
            sendToParentTableData={handleOneTimeCostDataUpdate}
          />
          <div style={{ fontStyle: 'italic' }} className={styles.detailItemLabel + ' pb-3'}>
            Note: For Final Delivery cost, please check the buyer's preferred Incoterm
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Calculated</h6>
        </Row>
        <Col xxl={8}>
          <Form.Label htmlFor="quoteControl" className={styles.detailItemLabel}>
            Estimated quote
            <span>
              {' '}
              = Moq * Unit cost + One-time cost
            </span>
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="text"
              id="quoteControl"
              value={estQuoteTotal}
              disabled={true}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="py-2">
        <Row className="pb-1">
          <h6 style={{ fontWeight: 'bold' }}>Expiration</h6>
        </Row>
        <Col lg={6} xxl={4}>
          <Form.Label
            htmlFor="quoteExpiryControl"
            className={styles.detailItemLabel}
          >
            Quote expires in
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              onChange={e => setExpiresIn(e.target.value)}
              defaultValue={expiresIn}
              id="quoteExpiryControl"
              required
            >
              <option>60</option>
              <option>90</option>
            </Form.Select>
            <InputGroup.Text>Days</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <Form.Label
            htmlFor="commentsControl"
            className={styles.detailItemLabel}
          >
            Other information (optional)
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              aria-label="With textarea"
              rows={5}
              id="commentsControl"
              onChange={e => setComments(e.target.value)}
              defaultValue={comments}
            />
          </InputGroup>
        </Col>
      </Row>
      {errorMsg?.length > 0 && (
        <div>
          <Alert variant="danger" onClose={() => setErrorMsg([])} dismissible>
            {errorMsg?.map(line => (
              <span key={'errMsg' + errorMsg?.indexOf(line)}>
                {line}
                <br />
              </span>
            ))}
          </Alert>
        </div>
      )}
      <Row className="py-2">
        <Stack direction="horizontal" gap={2} className="mx-auto">
          {!iteration && (
            <Button
              ref={saveDraftBtnRef}
              onClick={handleSaveQuoteAsDraft}
              className={styles.buttonOutline + ' py-2'}
            >
              Save draft
            </Button>
          )}
          <Button
            ref={submitQuoteBtnRef}
            type="submit"
            className={styles.button + ' py-2'}
          >
            Submit
          </Button>
        </Stack>
      </Row>
    </Form>
  )
}
