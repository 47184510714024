import styles from '../styles/styles.module.css'
import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Stack,
  Alert,
  Card,
  ListGroup,
  Nav,
  Image,
  OverlayTrigger,
  Popover
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  updateQuoteStatus,
  updateProjectStatus
} from '../services/buyerServices'
import { sendNotification } from '../services/notificationServices'
import * as constants from '../constants'
import { PageRoutes } from '../constants'
import useUser from '../hooks/useUser'
import iconFullService from '../images/icons/supplierCreds/full_service.svg'
import iconManufacturer from '../images/icons/supplierCreds/manufacturer.svg'
import iconDecoPlant from '../images/icons/supplierCreds/deco_plant.svg'
import iconService from '../images/icons/supplierCreds/service.svg'
import iconInnovation from '../images/icons/supplierCreds/innovation.svg'
import iconCompliance from '../images/icons/supplierCreds/compliance.svg'
import iconSustainability from '../images/icons/supplierCreds/sustainability.svg'
import { useToast } from '../ToastContext'

export default function QuoteCardHorizontal(props) {
  const { addToast } = useToast()
  const projectId = props?.projectId
  const quoteId = props?.quoteData?.id
  const data = props?.quoteData

  const [user, userLoading] = useAuthState(auth)
  const userData = useUser(user?.email)

  const supplier = useUser(data?.createdBy)

  const [finishOns, setFinishOns] = useState([])
  const [decoCounters, setDecoCounters] = useState([])

  const [selected, setSelected] = useState(
    data?.status === constants.STATUS_QUOTE_SUBMITTED ||
      data?.status === constants.STATUS_QUOTE_DRAFT
      ? false
      : true
  )

  useEffect(() => {
    setFinishOns(getInitialFinishOnObject(data))
    setDecoCounters(getInitialDecorationCounterObject(data))
  }, [data])

  function getInitialFinishOnObject(data) {
    let results = []
    const finishObjects = data?.unitCost?.filter(
      uc => uc?.category === 'Finish'
    )

    for (let i = 0; i < finishObjects?.length; i++) {
      results.push({ ...finishObjects[i], onOffOption: true })
    }
    return results
  }

  function getInitialDecorationCounterObject(data) {
    let results = []
    const decoObjects = data?.unitCost?.filter(
      uc => uc?.category === 'Decoration'
    )

    for (let i = 0; i < decoObjects?.length; i++) {
      results.push({ ...decoObjects[i], counter: 1 })
    }
    return results
  }

  // this function returns the number of days remaining before expiration
  function getRemainingDays(expiresInValue, submittedOn) {
    var results
    results = Math.floor(
      expiresInValue -
      moment
        .duration(moment().diff(moment.unix(submittedOn?.seconds)))
        .asDays()
    )
    return results
  }

  const handleSelectQuote = async e => {
    e.preventDefault()
    try {
      updateProjectStatus(
        projectId,
        constants.STATUS_PROJECT_SUPPLIER_SELECTED
      ).then(() => {
        try {
          updateQuoteStatus(data?.id, constants.STATUS_QUOTE_SELECTED).then(
            () => {
              sendNotification(
                constants.NOTI_TYPE_SUPPLIER_QUOTE_SELECTED,
                projectId,
                data?.id
              )
              sendNotification(
                constants.NOTI_TYPE_BUYER_QUOTE_SELECTED,
                projectId,
                data?.id
              )

              setSelected(true)
              props.sendToParentQuoteSelected()
            }
          )
        } catch {
          addToast('Selecting quote failed, please try again.', 'danger')
        }
      })
    } catch {
      addToast('Updating project status failed, please try again.', 'danger')
    }
  }

  function getUnitCostTotal() {
    let unitCostSum = 0
    if (data?.shippingCost) { // TODO: outdated calculation of unit cost, shippingCost is moved to one-time cost
      for (const obj of data?.unitCost) {
        if (obj?.price !== '') {
          if (obj?.category === 'Finish') {
            if (
              finishOns?.filter(fo => fo?.id === obj?.id)?.at(0)?.onOffOption
            ) {
              unitCostSum = unitCostSum + parseFloat(obj?.price)
            }
          } else if (obj?.category === 'Decoration') {
            unitCostSum =
              unitCostSum +
              decoCounters?.filter(dc => dc?.id === obj?.id)?.at(0)?.counter *
              parseFloat(obj?.price)
          } else {
            unitCostSum = unitCostSum + parseFloat(obj?.price)
          }
        }
      }
    } else { // new calculation of unit cost
      for (const obj of data?.unitCost) {
        if (obj?.price !== '') {
          unitCostSum = unitCostSum + parseFloat(obj?.price)
        }
      }
    }
    return unitCostSum
  }

  const iconHeight = '36px'

  const TooltipIcon = ({ quoteId, label, children }) => {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="bottom"
        overlay={
          <Popover id={`popover-quote-${quoteId}-${label}`}>
            <Popover.Body>
              <div>{label}</div>
            </Popover.Body>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    )
  }

  return (
    <Card>
      {user && userData?.role?.admin && (
        <Card.Header>
          <Alert variant="danger" className="mt-2 my-2">
            <Stack direction="horizontal" gap={3}>
              <h5 className="my-0">Admin only</h5>
              <div className={styles.cardListItemLabel}>Author</div>
              <div className={styles.cardListItemValue}>
                {supplier?.firstName +
                  ' ' +
                  supplier?.lastName +
                  ' (' +
                  supplier?.email +
                  ')'}
              </div>
            </Stack>
          </Alert>
        </Card.Header>
      )}
      <ListGroup className="list-group-flush py-2" horizontal>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              Supplier
            </div>
            <hr className="my-1" />
            <div
              className={styles.cardListItemValue}
              style={{ lineHeight: iconHeight }}
            >
              {supplier?.company}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              Strength
            </div>
            <hr className="my-1" />
            <div style={{ lineHeight: '36px' }}>
              {supplier?.strength?.fullService && (
                <TooltipIcon quoteId={quoteId} label={'Full Service'}>
                  <Image
                    src={iconFullService}
                    width={iconHeight}
                    alt="Full Service"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.manufacturer && (
                <TooltipIcon quoteId={quoteId} label={'Manufacturer'}>
                  <Image
                    src={iconManufacturer}
                    width={iconHeight}
                    alt="Manufacturer"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.decoPlant && (
                <TooltipIcon quoteId={quoteId} label={'Deco Plant'}>
                  <Image
                    src={iconDecoPlant}
                    width={iconHeight}
                    alt="Deco Plant"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.customerService && (
                <TooltipIcon quoteId={quoteId} label={'Customer Service'}>
                  <Image
                    src={iconService}
                    width={iconHeight}
                    alt="Customer Service"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.innovation && (
                <TooltipIcon quoteId={quoteId} label={'Innovation'}>
                  <Image
                    src={iconInnovation}
                    width={iconHeight}
                    alt="Innovation"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.compliance && (
                <TooltipIcon quoteId={quoteId} label={'Compliance'}>
                  <Image
                    src={iconCompliance}
                    width={iconHeight}
                    alt="Compliance"
                  />
                </TooltipIcon>
              )}
              {supplier?.strength?.sustainability && (
                <TooltipIcon quoteId={quoteId} label={'Sustainability'}>
                  <Image
                    src={iconSustainability}
                    width={iconHeight}
                    alt="Sustainability"
                  />
                </TooltipIcon>
              )}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              Unit cost
            </div>
            <hr className="my-1" />
            <div
              className={styles.cardListItemValue}
              style={{ lineHeight: iconHeight }}
            >
              {'$' + getUnitCostTotal().toFixed(2)}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              MOQ
            </div>
            <hr className="my-1" />
            <div
              className={styles.cardListItemValue}
              style={{ lineHeight: iconHeight }}
            >
              {data?.moq ? data?.moq : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              Production lead time
            </div>
            <hr className="my-1" />
            <div
              className={styles.cardListItemValue}
              style={{ lineHeight: iconHeight }}
            >
              {data?.productionTimeline + ' Weeks'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <div
              className={styles.cardListItemLabel}
              style={{ lineHeight: iconHeight }}
            >
              Shipping location
            </div>
            <hr className="my-1" />
            <div
              className={styles.cardListItemValue}
              style={{ lineHeight: iconHeight }}
            >
              {data?.shippingLocation ? data?.shippingLocation : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item
          className="text-center align-self-center"
          style={{ width: '15%', borderWidth: '0px' }}
        >
          <Stack direction="vertical" gap={1}>
            <Button className={styles.buttonOutline + ' mb-2'} size="sm">
              <Nav.Link as={Link} to={PageRoutes.QUOTE_DETAILS + '/' + data.id}>
                Details
              </Nav.Link>
            </Button>
            <Button
              size="sm"
              className={styles.button}
              disabled={
                selected
                  ? true
                  : getRemainingDays(data?.expiresIn, data?.updatedOn) >= 0
                    ? false
                    : true
              }
              onClick={handleSelectQuote}
            >
              {selected ? 'Selected' : 'Select'}
            </Button>
          </Stack>
        </ListGroup.Item>
      </ListGroup>
      {/*{data?.status !== constants.STATUS_QUOTE_DRAFT && (//&& data?.status !== constants.STATUS_QUOTE_INIT_ASSESSMENT && (
      <Card.Footer>
        <Messages 
          title={"Comments"} 
          buttonLabel={"Submit"}
          quoteId={data?.id} 
          type={constants.TYPE_MSG_QUOTE_COMMENTS}
          numberOfLines={3} />
      </Card.Footer>
      )}*/}
    </Card>
  )
}
