import { auth, db } from '../firebase'
import {
  addDoc,
  getDocs,
  collection,
  serverTimestamp,
  query,
  where
} from 'firebase/firestore'
import * as constants from '../constants'
import { getProjectData, getProjectByRef, getSelectedQuote } from './buyerServices'
import { getQuote, getSupplierAdjustedQuotes } from './supplierServices';
import { getUser } from './userServices';

export async function sendNotification(type, projectId, quoteId) {
  const adminEmail = 'hello@packagemaven.com'
  let project = await getProjectData(projectId)

  if (type === constants.NOTI_TYPE_BUYER_PROJECT_CREATED ||
    type === constants.NOTI_TYPE_BUYER_PROJECT_POSTED) {
    const user = await getUser(project?.createdBy)
    createNotificationRecord(
      type,
      [project?.createdBy],
      user?.firstName,
      user?.lastName,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_MY_PROJECTS
    )
  }
  if (type === constants.NOTI_TYPE_SUPPLIER_PROJECT_POSTED) {
    const sendTo = await getAllSupplierEmails()
    //const testSendTo = await getAllAdminEmails()
    createNotificationRecord(
      type,
      sendTo,
      null,
      null,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_BROWSE_PROJECTS
    )
  }
  if (type === constants.NOTI_TYPE_BUYER_QUOTE_SUBMITTED) {
    const user = await getUser(project?.createdBy)
    createNotificationRecord(
      type,
      [project?.createdBy],
      user?.firstName,
      user?.lastName,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_BUYER_PROGRESS + projectId
    )
  }
  if (type === constants.NOTI_TYPE_BUYER_QUOTE_ADJUST_SUBMITTED) {
    const user = await getUser(project?.createdBy)
    const selectedQuote = await getSelectedQuote(projectId) // currently this method returns an array
    if (selectedQuote?.length > 0) {
      createNotificationRecord(
        type,
        [project?.createdBy],
        user?.firstName,
        user?.lastName,
        projectId,
        project?.name,
        quoteId,
        constants.LINK_BUYER_PROGRESS + projectId + '?quoteId=' + selectedQuote[0]?.id + '&adjustedQuoteId=' + quoteId
      )
    }
  }
  if (type === constants.NOTI_TYPE_SUPPLIER_QUOTE_SUBMITTED ||
    type === constants.NOTI_TYPE_SUPPLIER_SAMPLE_SHIPPED) {
    const user = await getUser(auth?.currentUser?.email)
    createNotificationRecord(
      type,
      [user?.email],
      user?.firstName,
      user?.lastName,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_MY_QUOTES
    )
  }
  if (type === constants.NOTI_TYPE_SUPPLIER_QUOTE_SELECTED ||
    type === constants.NOTI_TYPE_SUPPLIER_SAMPLE_REQUESTED ||
    type === constants.NOTI_TYPE_SUPPLIER_DESIGN_READY_FOR_REVIEW) {
    const quote = await getQuote(quoteId);
    const user = await getUser(quote?.createdBy)
    createNotificationRecord(
      type,
      [quote?.createdBy],
      user?.firstName,
      user?.lastName,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_SUPPLIER_PROGRESS + projectId + '?quoteId=' + quoteId)
  }
  if (type === constants.NOTI_TYPE_BUYER_SAMPLE_REQUESTED ||
    type === constants.NOTI_TYPE_BUYER_DESIGN_SUBMITTED ||
    type === constants.NOTI_TYPE_BUYER_SAMPLE_SHIPPED ||
    type === constants.NOTI_TYPE_BUYER_SAMPLE_DELIVERY_CONFIRMED ||
    type === constants.NOTI_TYPE_BUYER_DESIGN_ACCEPTED) {
    const user = await getUser(project?.createdBy)
    createNotificationRecord(
      type,
      [project?.createdBy],
      user?.firstName,
      user?.lastName,
      projectId,
      project?.name,
      quoteId,
      constants.LINK_BUYER_PROGRESS + projectId + '?quoteId=' + quoteId)
  }
  if (type === constants.NOTI_TYPE_PROJECT_COMMENTS) {
    const user = await getUser(auth.currentUser.email)
    let sendTo;
    if (user?.role?.admin) {
      sendTo = await getUser(project?.createdBy)
    }
    createNotificationRecord(
      type,
      user.role.admin ? [sendTo.email] : [adminEmail],
      user.role.admin ? sendTo?.firstName : 'Admin',
      user.role.admin ? sendTo?.lastName : '',
      projectId,
      project?.name,
      quoteId, // null
      constants.LINK_BUYER_PROGRESS + projectId)
  }
  if (type === constants.NOTI_TYPE_QUOTE_COMMENTS) {
    const user = await getUser(auth.currentUser.email)
    const quote = await getQuote(quoteId)
    project = await getProjectByRef(quote?.projectRef)
    console.log('quote', quote)
    console.log('project', project)
    let sendToEmails
    let targetLink
    if (user?.role?.admin) {
      sendToEmails = [quote?.createdBy, project?.createdBy]
      targetLink = 'Buyer Link: ' + constants.LINK_BUYER_PROGRESS + project?.id + '?quoteId=' + quoteId + '\nSupplier Link: ' + constants.LINK_SUPPLIER_PROGRESS + project?.id + '?quoteId=' + quoteId
    } else if (user?.email === project?.createdBy) {
      sendToEmails = [quote?.createdBy, adminEmail]
      targetLink = constants.LINK_SUPPLIER_PROGRESS + project?.id + '?quoteId=' + quoteId
    } else if (user?.email === quote?.createdBy) {
      sendToEmails = [project?.createdBy, adminEmail]
      targetLink = constants.LINK_BUYER_PROGRESS + project?.id + '?quoteId=' + quoteId
    } else {
      sendToEmails = [adminEmail]
      targetLink = '[No link provided and this should have not been fired. Let JS know this occured]'
    }
    console.log('sendToEmails', sendToEmails)
    createNotificationRecord(
      type,
      sendToEmails,
      '',
      '',
      project?.id,
      project?.name,
      quoteId,
      targetLink)
  }
}

// this function inserts a doc in notifications collection in Firestore, the record will be picked up by Zapier for email notification fire.
async function createNotificationRecord(type, sendTo, firstName, lastName, projectId, projectName, quoteId, link) {
  try {
    const notificationsRef = collection(db, 'notifications')
    await addDoc(notificationsRef, {
      type: type,
      sendTo: sendTo, // array of email addresses
      firstName: firstName,
      lastName: lastName,
      projectId: projectId,
      projectName: projectName,
      quoteId: quoteId,
      link: link,
      createdBy: auth?.currentUser?.email,
      createdOn: serverTimestamp()
    })
      .then(() => {
        console.log('Adding a notification record to Firestore successful')
      })
      .catch(err => {
        console.log(err)
      })
  } catch (e) {
    console.error('Error creating notification record: ', e)
  }
}

// this function retrieves all supplier emails
async function getAllSupplierEmails() {
  try {
    const allSuppliersQuery = query(
      collection(db, 'users'),
      where('role.supplier', '==', true)
    )
    const suppliersDocsSnapshot = await getDocs(allSuppliersQuery)

    let results = []
    suppliersDocsSnapshot.forEach(doc => {
      results.push(doc?.id)
    })

    return results
  } catch (e) {
    throw new Error(`Error getting all suppliers: ${e}`)
  }
}

async function getAllAdminEmails() {
  return [
    'jungsup.lee@gmail.com',
    'yangsw_78@yahoo.com',
    'eugenedk@gmail.com'
  ]
}
