import { useEffect, useState } from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import '@cyntler/react-doc-viewer/dist/index.css'

export const FileGallery = props => {
  const files = props?.files
  const [documents, setDocuments] = useState([])
  const [viewerKey, setViewerKey] = useState(0)
  const [activeDocument, setActiveDocument] = useState(null)

  useEffect(() => {
    if (files) {
      let docs = []
      for (const file of files) {
        const docObj = {
          uri:
            file?.fileExt === 'pdf'
              ? `data:application/pdf;base64,${file?.base64String}`
              : file?.fileExt === 'png'
                ? `data:image/png;base64,${file?.base64String}`
                : file?.fileExt === 'bmp'
                  ? `data:image/bmp;base64,${file?.base64String}`
                  : file?.fileExt === 'doc'
                    ? `data:application/msword;base64,${file?.base64String}` // Public URLs only!
                    : file?.fileExt === 'docx'
                      ? `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${file?.base64String}` // Public URLs only!
                      : file?.fileExt === 'htm'
                        ? `data:text/htm;base64,${file?.base64String}`
                        : file?.fileExt === 'html'
                          ? `data:text/html;base64,${file?.base64String}`
                          : file?.fileExt === 'jpg'
                            ? `data:image/jpg;base64,${file?.base64String}`
                            : file?.fileExt === 'jpeg'
                              ? `data:image/jpeg;base64,${file?.base64String}`
                              : file?.fileExt === 'ppt'
                                ? `data:application/vnd.ms-powerpoint;base64,${file?.base64String}` // Public URLs only!
                                : file?.fileExt === 'pptx'
                                  ? `data:applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${file?.base64String}` // Public URLs only!
                                  : file?.fileExt === 'tiff'
                                    ? `data:image/tiff;base64,${file?.base64String}`
                                    : file?.fileExt === 'txt'
                                      ? `data:text/plain;base64,${file?.base64String}`
                                      : file?.fileExt === 'xls'
                                        ? `data:application/vnd.ms-excel;base64,${file?.base64String}` // Public URLs only!
                                        : file?.fileExt === 'xlsx'
                                          ? `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file?.base64String}` // Public URLs only!
                                          : file?.fileExt === 'webp'
                                            ? `data:image/webp;base64,${file?.base64String}`
                                            : file?.fileExt === 'mp4'
                                              ? `data:video/mp4;base64,${file?.base64String}`
                                              : `data:image/png;base64,${file?.base64String}`
        }
        if (file?.fileExt === 'pdf') {
          // for some reason fileType needs to be specified for pdf
          docObj['fileType'] = file?.fileExt
        }
        docs.push(docObj)
      }
      setDocuments(docs)
      setActiveDocument(docs?.length > 0 ? docs[0] : null)
    }
  }, [files])

  useEffect(() => {
    setTimeout(() => {
      setViewerKey(prevKey => prevKey + 1)
    }, 100)
  }, [documents])

  const handleDocumentChange = document => {
    setActiveDocument(document)
    setViewerKey(prevKey => prevKey + 1)
  }

  return (
    <DocViewer
      pluginRenderers={DocViewerRenderers}
      documents={documents}
      initialActiveDocument={activeDocument}
      key={viewerKey.toString()}
      onDocumentChange={handleDocumentChange}
      config={{
        header: {
          disableHeader: false,
          disableFileName: true,
          retainURLParams: false
        },
        csvDelimiter: ',', // "," as default,
        pdfZoom: {
          defaultZoom: 1, // 1 as default,
          zoomJump: 0.1 // 0.1 as default,
        },
        pdfVerticalScrollByDefault: false // false as default
      }}
    />
  )
}
