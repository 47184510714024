import styles from '../styles/styles.module.css'
import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Stack,
  Row,
  Col,
  Form,
  ListGroup,
  Container
} from 'react-bootstrap'
import moment from 'moment'
import {
  addQuoteMessages,
  getQuoteMessages,
  addProjectMessages,
  getProjectMessages
} from '../services/supplierServices'
import * as constants from '../constants'
import { sendNotification } from '../services/notificationServices'

export default function Messages(props) {
  const projectId = props?.projectId
  const quoteId = props?.quoteId
  const commentsType = props?.type
  const numberOfLines = props?.numberOfLines ? props?.numberOfLines : 4
  const buttonLabel = props?.buttonLabel ? props?.buttonLabel : 'Submit'
  const disabled = props?.disabled ? props?.disabled : false
  const commentsSubmitBtnRef = useRef()
  const [comments, setComments] = useState('')
  const [prevComments, setPrevComments] = useState([])
  const [prevCommentsRecent, setPrevCommentsRecent] = useState([])
  const [prevCommentsAll, setPrevCommentsAll] = useState([])
  const [commentsStateChanged, setCommentsStateChanged] = useState(false)
  const initialNumberComments = 3

  /*useEffect(() => {
        async function commentsFetchData() {
          try {
            let response;
            if (projectId) {
                response = await getProjectMessages(projectId, commentsType);
            } else if (quoteId) {
                response = await getQuoteMessages(quoteId, commentsType);
            } else  {
                response = ["Error: Invalid Id."];
            }
            setPrevComments(response);
          } catch (error) {
            console.log(error);
          }
        }
        commentsFetchData();
    }, []);*/

  useEffect(() => {
    async function commentsFetchData() {
      try {
        let response
        if (projectId) {
          response = await getProjectMessages(projectId, constants.TYPE_MSG_PRJ_ALL)
        } else if (quoteId) {
          response = await getQuoteMessages(quoteId, constants.TYPE_MSG_QUOTE_ALL)
        } else {
          response = ['Error: Invalid Id.']
        }

        if (response?.length > initialNumberComments) {
          const recentComments = []
          for (let i = 0; i < initialNumberComments; i++) {
            recentComments.push(response[i])
          }
          setPrevComments(recentComments)
          setPrevCommentsRecent(recentComments)
          setPrevCommentsAll(response)
        } else {
          setPrevComments(response)
        }
      } catch (error) {
        console.log(error)
      }
    }
    commentsFetchData()
  }, [commentsStateChanged])

  const handleCommentsSubmit = async e => {
    e.preventDefault()
    if (commentsSubmitBtnRef.current && comments.trim() !== '') {
      commentsSubmitBtnRef.current.setAttribute('disabled', 'disabled')

      try {
        if (projectId) {
          addProjectMessages(projectId, commentsType, comments).then(() => {
            setCommentsStateChanged(!commentsStateChanged)
            setComments('')
            sendNotification(constants.NOTI_TYPE_PROJECT_COMMENTS, projectId, null)
          })
        } else if (quoteId) {
          addQuoteMessages(quoteId, commentsType, comments).then(() => {
            setCommentsStateChanged(!commentsStateChanged)
            setComments('')
            sendNotification(constants.NOTI_TYPE_QUOTE_COMMENTS, null, quoteId)
          })
        } else {
          console.log('Error: Invalid Id')
        }
      } catch {
      }

      commentsSubmitBtnRef.current.removeAttribute('disabled')
    }
  }

  const handleViewMore = async e => {
    e.preventDefault()
    if (prevCommentsAll?.length > 0) {
      setPrevComments(prevCommentsAll)
    }
  }

  const handleViewLess = async e => {
    e.preventDefault()
    if (prevCommentsAll?.length > 0) {
      setPrevComments(prevCommentsRecent)
    }
  }

  return (
    <>
      <Row>
        <Form.Label className={styles.detailItemLabel}>
          {props.title}
        </Form.Label>
      </Row>
      <Row className="mb-2">
        <Container>
          <ListGroup>
            {prevComments && prevComments?.length > 0 ? (
              prevComments?.map(c => (
                <ListGroup.Item key={'comments' + prevComments.indexOf(c)}>
                  <Row>
                    <Col lg={6}>
                      <span className={styles.cardListItemValue}>{c?.msg}</span>
                    </Col>
                    <Col lg={{ span: 5, offset: 1 }} className="text-end">
                      <span className={styles.cardListItemValue}>
                        {moment
                          .unix(c?.createdOn?.seconds)
                          .format('MM/DD/YYYY h:mm A')}
                      </span>
                      <br />
                      {/*<span className={styles.cardListItemValue}>
                                    {c?.createdBy}
                                </span>*/}
                      <span
                        className={styles.cardListItemValue}
                        style={{
                          color: c?.role?.admin
                            ? 'dodgerblue'
                            : c?.role?.buyer
                              ? 'seagreen'
                              : c?.role?.supplier
                                ? 'orange'
                                : 'red'
                        }}
                      >
                        {c?.role?.admin
                          ? c?.firstName + ' (Package Maven)'
                          : c?.role?.buyer
                            ? c?.firstName + ' (Buyer)'
                            : c?.role?.supplier
                              ? c?.firstName + ' (Supplier)'
                              : 'N/A'}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>
                <span className={styles.cardListItemValue}>
                  No messages yet.
                </span>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Container>
      </Row>
      {(prevComments?.length > 0) && (prevComments?.length < prevCommentsAll?.length) && (
        <Row className="mb-2">
          <div className="text-center">
            <Button
              size="sm"
              className={styles.buttonOutline}
              onClick={handleViewMore}
            >
              View all
            </Button>
          </div>
        </Row>
      )}
      {(prevComments?.length > 0) && (prevComments?.length === prevCommentsAll?.length) && (
        <Row className="mb-2">
          <div className="text-center">
            <Button
              size="sm"
              className={styles.buttonOutline}
              onClick={handleViewLess}
            >
              View less
            </Button>
          </div>
        </Row>
      )}
      <Row>
        <Form.Group className="mt-1 mb-2" controlId="comments.control">
          <Form.Control
            as="textarea"
            onChange={e => setComments(e.target.value)}
            rows={numberOfLines}
            value={comments}
            disabled={disabled}
          />
        </Form.Group>
        <div className="text-end">
          <Button
            ref={commentsSubmitBtnRef}
            size="sm"
            className={styles.buttonOutline}
            onClick={handleCommentsSubmit}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </div>
      </Row>
    </>
  )
}
