import styles from '../styles/styles.module.css'
import { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Alert, Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
//import { auth } from "../firebase";
import { updateProjectData } from '../services/buyerServices'
import { getFile } from '../services/fileStorageServices'
import moment from 'moment'
import * as constants from '../constants'
import useProjectForm from '../hooks/useProjectForm'
import { useToast } from '../ToastContext'

export const ProjectEdit = ({
  projectData,
  currentStep,
  sendToParentProjectSubmittedForIA
}) => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const submitBtnRef = useRef()

  const projectId = projectData?.id

  const { form, buildProject, designFiles } = useProjectForm({
    project: projectData
  })

  const onSaveDraft = async e => {
    e.preventDefault()

    try {
      updateProjectData(
        projectId,
        buildProject(),
        designFiles,
        constants.STATUS_PROJECT_DRAFT
      ).then(() => {
        addToast('Project draft saved successfully', 'success')
      })
    } catch {
      addToast('Saving project draft failed, please try again.', 'danger')
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute('disabled', 'disabled')

      try {
        updateProjectData(
          projectId,
          buildProject(),
          designFiles,
          constants.STATUS_PROJECT_INIT_ASSESSMENT
        ).then(() => {
          if (currentStep === 0) {
            addToast('Project submitted successfully. Package Maven expert will run the initial assessment, and provide feedback.', 'success')
            sendToParentProjectSubmittedForIA?.()
          } else {
            addToast('Project updated successfully. Package Maven expert will run the initial assessment, and provide feedback.', 'success')
          }
        })
      } catch {
        addToast('Submitting project failed, please try again.', 'danger')
        //submitBtnRef.current.removeAttribute("disabled");
      }
      submitBtnRef.current.removeAttribute('disabled') // reopen submit button after updating project as the user stays on the same step
    }
  }

  return (
    <>
      <Container fluid={true}>
        <Form onSubmit={onSubmit}>
          {form}
          <Row className="px-3 py-3">
            <Col xs={5} md={4} xl={3} className="px-0">
              <div className="d-grid gap-2">
                <Button
                  variant="outline-secondary"
                  className="py-2"
                  size="sm"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              md={{ span: 4, offset: 4 }}
              xl={{ span: 3, offset: 6 }}
              className="px-0"
            >
              <div className="d-grid gap-2">
                {currentStep === 0 && (
                  <Button
                    onClick={onSaveDraft}
                    className={styles.buttonOutline + ' py-2'}
                    size="sm"
                  >
                    Save draft
                  </Button>
                )}
                <Button
                  ref={submitBtnRef}
                  type="submit"
                  className={styles.button + ' py-2'}
                  size="sm"
                >
                  {currentStep === 0 ? 'Submit for assessment' : 'Update'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
