import { useEffect, useState } from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { NewsPost } from '../types'
import { useParams } from 'react-router-dom'
import { Footer } from '../components/Footer'
import { convertWordpressPostToNewsPost } from '../utils/news'
import { formatDate } from '../utils/date'

const NewsPostPage = () => {
  const { slug } = useParams()
  const [post, setPost] = useState<NewsPost>()

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_NEWS_WORDPRESS_API_ENDPOINT}?slug=${slug}`
    )
      .then(response => response.json())
      .then(posts => {
        if (posts.length > 0) {
          setPost(convertWordpressPostToNewsPost(posts[0]))
        }
      })
      .catch(error => console.error('Error fetching post:', error))
  }, [])

  return (
    <Container fluid={true} className="px-0 py-0">
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={8} xxl={6}>
          <Stack className="mx-1 mx-xl-5 mb-5">
            {post && (
              <div>
                <h1
                  className="mx-5 pt-5 fw-semibold"
                  dangerouslySetInnerHTML={{ __html: post?.title }}
                />
                <img
                  className="my-4"
                  style={{
                    borderRadius: 10,
                    aspectRatio: 16 / 9,
                    marginBottom: 15,
                    width: '100%'
                  }}
                  src={post?.imageUrl}
                />
                <div
                  className="mx-5 fs-5 newsContent"
                  dangerouslySetInnerHTML={{ __html: post.contentHtml }}
                />
              </div>
            )}
          </Stack>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export default NewsPostPage
