import styles from '../styles/styles.module.css'
import { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  Card,
  Image,
  Alert
} from 'react-bootstrap'
import * as constants from '../constants'
import { ProjectEdit } from './ProjectEdit'
import Messages from './Messages'
import iconMessage from '../images/icons/communication.png'
import iconInstruction from '../images/icons/instruction.png'

export const StepBuyerInitialAssessment = props => {
  //const location = useLocation();
  //const stateObj = JSON.parse(location?.state);
  const projectData = props?.projectData
  const currentStep = props?.currentStep // current step number should be 1 for initial assessment
  const [showAlert, setShowAlert] = useState(true)

  return (
    <Container fluid={true}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Stack direction="horizontal" gap={5}>
              <h4>Initial assessment</h4>
            </Stack>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="px-3">
              <Alert
                className={styles.instructionBackgroundColor + ' my-2'}
                onClose={() => setShowAlert(false)}
              >
                <Row className="py-2">
                  <Col xs={2} lg={2} className="text-end">
                    <div className="mb-2">
                      <Image src={iconInstruction} />
                    </div>
                  </Col>
                  <Col xs={10} lg={9}>
                    <p style={{ fontWeight: 'bold' }}>Initial assessment</p>
                    <p>
                      This step is for you to receive professional feedback from
                      our packaging experts.
                    </p>
                    <p>
                      <div className="mb-2">
                        <span style={{ fontWeight: 'bold' }}>Step 1.</span> Our
                        experts will review your project and provide
                        recommendations and suggestions.
                      </div>
                      <div className="mb-2">
                        <span style={{ fontWeight: 'bold' }}>Step 2.</span> Once
                        the assessment is complete, your project will proceed to
                        the next step automatically.
                      </div>
                    </p>
                    <p className="mb-0">
                      If you have any questions in the meantime, please leave
                      comments in the messaging section at the bottom of the
                      page.
                    </p>
                  </Col>
                </Row>
              </Alert>
            </Row>
            <Row className="px-0">
              <ProjectEdit
                projectData={projectData}
                currentStep={currentStep}
              />
            </Row>
            <Row className="px-3 pt-2">
              <Card className="px-0">
                <Card.Title className="px-3 pt-3 pb-2">
                  <Image src={iconMessage} />
                  <span className="ps-3">Initial assessment feedback</span>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Messages
                    title={'Please submit any comments or questions.'}
                    buttonLabel={'Submit'}
                    projectId={projectData?.id}
                    type={constants.TYPE_MSG_PRJ_INIT_ASESSMENT}
                    numberOfLines={3}
                  />
                </Card.Body>
              </Card>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
}
