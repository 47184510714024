import { db } from '../firebase'
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  serverTimestamp,
  orderBy,
  query,
  where,
  getCountFromServer
} from 'firebase/firestore'
import axios from 'axios'
import moment from 'moment'
import * as constants from '../constants'

// this function retrieves all projects with given status from Firestore
export async function getAllProjects(status) {
  let results = []
  try {
    const allProjectsQuery = query(
      collection(db, 'projects'),
      where('status', '==', status),
      orderBy('updatedOn', 'desc')
    )
    const projectsDocsSnapshot = await getDocs(allProjectsQuery)
    projectsDocsSnapshot.forEach(doc => {
      results.push({ ...doc?.data(), id: doc?.id })
    })
  } catch (e) {
    console.error('Error getting all projects data with given status: ', e)
  }
  return results
}

// this function retrieves all quotes and associated projects with given status from Firestore
export async function getAllQuotes(status) {
  let results = []
  try {
    const allQuotesQuery = query(
      collection(db, 'quotes'),
      where('status', '==', status),
      orderBy('updatedOn', 'desc')
    )
    const quotesDocsSnapshot = await getDocs(allQuotesQuery)
    for (const quoteDoc of quotesDocsSnapshot.docs) {
      const projectDocSnap = await getDoc(quoteDoc?.data().projectRef)
      results.push({
        ...quoteDoc?.data(),
        id: quoteDoc?.id,
        project: projectDocSnap?.data()
      })
    }
  } catch (e) {
    console.error('Error getting all quotes data with given status: ', e)
  }
  return results
}

// this function retrieves the total number of project documents from Firestore
export async function getTotalProjectsCount() {
  let results
  try {
    const projectsCol = collection(db, 'projects')
    const snapshot = await getCountFromServer(projectsCol)
    results = snapshot?.data()?.count
  } catch (e) {
    console.error('Error getting total project count: ', e)
  }
  return results
}
