import styles from '../styles/styles.module.css'
import { useState, useEffect, useRef } from 'react'
//import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Form,
  Accordion,
  Stack
} from 'react-bootstrap'
import { setEngagedQuoteStatus } from '../services/buyerServices'
import { auth } from '../firebase'
import * as constants from '../constants'
import useUser from '../hooks/useUser'
import LabeledInputSelect from '../components/LabeledInputSelect'
import { Courier } from '../types'
import { useToast } from '../ToastContext'

export const FormSampleSent = props => {
  const { addToast } = useToast()
  const submitBtnRef = useRef()

  const supplier = useUser(auth?.currentUser?.email)
  const [courier, setCourier] = useState('')
  const [trackingNumber, setTrackingNumber] = useState('')

  useEffect(() => {
    if (supplier) {
      // set buyer info
    }
  }, [supplier])

  const onSampleRequestSubmit = async e => {
    e.preventDefault()
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute('disabled', 'disabled')

      const status =
        props?.type === 'nonDeco'
          ? constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT // non deco sample
          : constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT // pre production sample

      try {
        setEngagedQuoteStatus(props?.quoteId, status, {
          courier: courier,
          trackingNumber: trackingNumber
        }).then(() => {
          addToast('Courier and tracking number recorded successfully.', 'success')
          props?.sendToParentSampleSent()
        })
      } catch {
        addToast('Recording courier and tracking number failed, please try again.', 'danger')
      }

      submitBtnRef.current.removeAttribute('disabled')
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Form onSubmit={onSampleRequestSubmit}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Stack direction="horizontal" gap={5}>
                      <h4>Payment terms & conditions</h4>
                    </Stack>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="pb-2">
                      <p>
                        <b>Delivery Arrangement and Sample Arrival:</b>
                        <div className="px-3 py-2">
                          <li>
                            The supplier must arrange the delivery of samples
                            and provide the tracking number within 72 hours of
                            receiving the sample request. The platform will
                            provide a link for the buyer to track the delivery
                            status and estimated arrival date.
                          </li>
                          <li>
                            Samples must arrive within the date range specified
                            in the delivery arrangement. Any changes to the
                            committed delivery date must be communicated through
                            the delivery website.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Confidentiality Agreement:</b>
                        <div className="px-3 py-2">
                          <li>
                            Both parties agree to maintain confidentiality of
                            all proprietary information related to the sample,
                            including design, specifications, and any other
                            sensitive data. This confidentiality agreement
                            remains in effect even after the conclusion of the
                            project.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Payment Terms:</b>
                        <div className="px-3 py-2">
                          <li>
                            The final invoice will be issued upon the conclusion
                            of the project.
                          </li>
                          <li>
                            If the project ends without a purchase order, the
                            sample cost will be invoiced at that time.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Questions or Concerns:</b>
                        <div className="px-3 py-2">
                          <li>
                            Please contact:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              hello@packagemaven.com
                            </span>
                          </li>
                        </div>
                      </p>
                      <Row className="justify-content-md-center">
                        <Col xs={8} lg={3} className="text-center">
                          <Form.Check
                            type={'checkbox'}
                            id={'ack-checkbox'}
                            label="Agree to terms & conditions"
                            feedback="You must agree before submitting."
                            required
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Stack direction="horizontal" gap={5}>
                      <h4>Delivery tracking information</h4>
                    </Stack>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={3}>
                        <LabeledInputSelect
                          controlId="courier.control"
                          label="Courier"
                          value={courier}
                          onChange={e => setCourier(e.target.value)}
                          options={[
                            Courier.FedEx,
                            Courier.DHL,
                            Courier.UPS,
                            'Other'
                          ]}
                          required
                        />
                      </Col>
                      <Col md={7}>
                        <Form.Group
                          className="mb-2"
                          controlId="tracking.control"
                        >
                          <Form.Label className={styles.listItemLabel}>
                            Tracking number
                            <span className="ms-1" style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={trackingNumber}
                            onChange={e => setTrackingNumber(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row className="mt-3 mb-2">
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="outline-primary"
                    size="sm"
                    ref={submitBtnRef}
                  >
                    <span>Submit</span>
                  </Button>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
