import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useDemoRequests } from '../hooks/useDemoRequests'
import { DemoRequestStatus } from '../types'
import {
  deleteDemoRequest,
  updateDemoRequestStatus
} from '../services/demoService'
import { useMemo, useState, useEffect } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'

const DemoRequests = () => {
  const [showCompleted, setShowCompleted] = useState(false)
  const [demoIdUpdating, setDemoIdUpdating] = useState<string>()
  const {
    demoRequests: initialDemoRequests,
    error,
    invalidateDemoRequests
  } = useDemoRequests()
  const [demoRequests, setDemoRequests] = useState(initialDemoRequests)

  useEffect(() => {
    setDemoRequests(initialDemoRequests)
  }, [initialDemoRequests])

  const filteredDemoRequests = useMemo(() => {
    return showCompleted
      ? demoRequests
      : demoRequests.filter(
          request => request.status !== DemoRequestStatus.Completed
        )
  }, [demoRequests, showCompleted])

  const handleStatusChange = async (
    id: string,
    newStatus: DemoRequestStatus
  ) => {
    try {
      setDemoIdUpdating(id)
      await updateDemoRequestStatus(id, newStatus)

      setDemoRequests(prevRequests =>
        prevRequests.map(request =>
          request.id === id ? { ...request, status: newStatus } : request
        )
      )
      console.log(`Updated status for ${id} to ${newStatus}`)

      invalidateDemoRequests()
    } catch (err) {
      console.error('Error updating status:', err)
    } finally {
      setDemoIdUpdating(undefined)
    }
  }

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this demo request?')) {
      try {
        await deleteDemoRequest(id)

        setDemoRequests(prevRequests =>
          prevRequests.filter(request => request.id !== id)
        )

        invalidateDemoRequests()
      } catch (err) {}
    }
  }

  return (
    <Container fluid={true}>
      <Row className="px-2 pt-3 pb-2">
        <Col>
          <h4 className="mb-3">Demo Requests</h4>
        </Col>
        <Col className="d-flex justify-content-end">
          <Form.Check
            type="checkbox"
            label="Show Completed"
            checked={showCompleted}
            onChange={e => setShowCompleted(e.target.checked)}
          />
        </Col>
      </Row>
      <Row className="px-2 pb-3">
        {!!error ? (
          <Col className="pb-3">{error}</Col>
        ) : (
          <Col className="pb-3 demoRequests">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th className="delete">Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredDemoRequests.map((demoRequest, index) => (
                  <tr key={index}>
                    <td>
                      {demoRequest.firstName} {demoRequest.lastName}
                    </td>
                    <td>{demoRequest.companyName}</td>
                    <td>{demoRequest.email}</td>
                    <td>
                      {demoRequest.id === demoIdUpdating ? (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          role="status"
                          size="sm"
                          className="mx-3"
                        />
                      ) : (
                        <Form.Select
                          value={demoRequest.status}
                          onChange={e =>
                            handleStatusChange(
                              demoRequest.id,
                              e.target.value as DemoRequestStatus
                            )
                          }
                        >
                          <option value={DemoRequestStatus.Pending}>
                            Pending
                          </option>
                          <option value={DemoRequestStatus.InProgress}>
                            In Progress
                          </option>
                          <option value={DemoRequestStatus.Completed}>
                            Completed
                          </option>
                        </Form.Select>
                      )}
                    </td>
                    <td className="delete">
                      <Button onClick={() => handleDelete(demoRequest.id)}>
                        <HiOutlineTrash size={20} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default DemoRequests
