// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD3MzzpR5goJpUHHsFFkPAIyiJvvf9hRTE',
  authDomain: 'package-maven.firebaseapp.com',
  projectId: 'package-maven',
  storageBucket: 'package-maven.appspot.com',
  messagingSenderId: '403569366342',
  appId: '1:403569366342:web:0edef33ae3ed26fddb8147',
  measurementId: 'G-9KNTNFYGPS'
}

const firebaseConfigDev = {
  apiKey: "AIzaSyBYoD-Optc90oz1QlWkVu6Dh88r_A2f8ko",
  authDomain: "package-maven-dev.firebaseapp.com",
  projectId: "package-maven-dev",
  storageBucket: "package-maven-dev.appspot.com",
  messagingSenderId: "929832299607",
  appId: "1:929832299607:web:419129a04445e6ffd30712",
  measurementId: "G-ZCSCTKLK64"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export default app
