import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

export const formatDate = (timestamp: Timestamp): string => {
  return moment(timestamp.toDate()).format('MM/DD/YYYY')
}

export const isPastTimestamp = (timestamp: Timestamp) => {
  const currentTimestamp = Timestamp.now() // 현재 시간의 Firestore Timestamp
  return timestamp < currentTimestamp // 과거인지 여부 판단
}
