import { auth, db } from '../firebase'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore'

// this function adds a new user to Firestore upon registering
export async function addUser(email, role) {
  //console.log("Adding a new user to Firestore starting ...");
  try {
    const userRef = doc(db, 'users', email) // id is email
    await setDoc(
      userRef,
      {
        email: email,
        updatedBy: auth?.currentUser?.email,
        updatedOn: serverTimestamp()
      },
      {
        merge: true
      }
    )
      .then(() => {
        console.log('Adding a new user to Firestore successful')
      })
      .catch(err => {
        console.log(err)
      })

    let roleObj = {}
    roleObj['role.' + role] = true
    await updateDoc(userRef, roleObj)
      .then(() => {
        console.log('Setting user role in Firestore successful')
      })
      .catch(err => {
        console.log(err)
      })
  } catch (e) {
    console.error('Error adding a new user to Firestore: ', e)
  }
  //console.log("Adding a new user to Firestore ended");
}

// this function retrieves user data for a given email address
export async function getUser(email) {
  //console.log("Getting a User Data starting ...");
  let results
  try {
    const userRef = doc(db, 'users', email) // id is email
    const docSnapshot = await getDoc(userRef)

    if (docSnapshot.exists()) {
      results = docSnapshot.data()
      console.log('User data retrieval successful')
    } else {
      console.log('No user data found with the email')
    }
  } catch (e) {
    console.error('Error getting user data with the email: ', e)
  }
  //console.log("Getting a User Data eneded");
  return results
}
