import styles from '../styles/styles.module.css'
import { useState, useEffect, useRef } from 'react'
//import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Accordion,
  Stack,
  Alert
} from 'react-bootstrap'
import { setEngagedQuoteStatus } from '../services/buyerServices'
import { auth } from '../firebase'
import * as constants from '../constants'
import useUser from '../hooks/useUser'
import { useToast } from '../ToastContext'

export const FormSampleCheckout = props => {
  const { addToast } = useToast()
  const submitBtnRef = useRef()

  const buyer = useUser(auth?.currentUser?.email)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [title, setTitle] = useState('')
  const [phone, setPhone] = useState('')
  const [location, setLocation] = useState('')

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  useEffect(() => {
    if (buyer) {
      setFirstName(buyer?.firstName ? buyer?.firstName : '')
      setLastName(buyer?.lastName ? buyer?.lastName : '')
      setCompany(buyer?.company ? buyer?.company : '')
      setTitle(buyer?.title ? buyer?.title : '')
      if (buyer?.phone && buyer?.phone.length > 0) {
        setPhone(buyer?.phone)
      }
      setLocation(buyer?.location ? buyer?.location : '')
    }
  }, [buyer])

  function getPraramObj() {
    return {
      deliveryAddress: {
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip
      }
      //adjustmentNeeds: adjustmentNeeds,
    }
  }

  const onSampleRequestSubmit = async e => {
    e.preventDefault()
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute('disabled', 'disabled')

      const status =
        props?.type === 'nonDeco'
          ? constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED // non deco sample request
          : constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED // pre production sample request

      try {
        setEngagedQuoteStatus(props?.quoteId, status, getPraramObj()).then(
          () => {
            addToast('Sample requested successfully.', 'success')
            props?.sendToParentSampleCheckoutComplete()
          }
        )
      } catch {
        addToast('Sample request failed, please try again.', 'danger')
      }

      submitBtnRef.current.removeAttribute('disabled')
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Form onSubmit={onSampleRequestSubmit}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Stack direction="horizontal" gap={5}>
                      <h4>Sample cost</h4>
                    </Stack>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="pb-2">
                      <div className={styles.accordContent}>
                        <span>Samples:</span>
                        <span className=" ms-2">
                          {props?.sampleCost
                            ? '$' + parseFloat(props?.sampleCost).toFixed(2)
                            : 'N/A'}
                        </span>
                      </div>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Stack direction="horizontal" gap={5}>
                      <h4>Payment terms & conditions</h4>
                    </Stack>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="pb-2">
                      <p>
                        <b>Delivery Arrangement and Sample Arrival:</b>
                        <div className="px-3 py-2">
                          <li>
                            The supplier must arrange the delivery of samples
                            and provide the tracking number within 72 hours of
                            receiving the sample request. The platform will
                            provide a link for the buyer to track the delivery
                            status and estimated arrival date.
                          </li>
                          <li>
                            Samples must arrive within the date range specified
                            in the delivery arrangement. Any changes to the
                            committed delivery date must be communicated through
                            the delivery website.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Confidentiality Agreement:</b>
                        <div className="px-3 py-2">
                          <li>
                            Both parties agree to maintain confidentiality of
                            all proprietary information related to the sample,
                            including design, specifications, and any other
                            sensitive data. This confidentiality agreement
                            remains in effect even after the conclusion of the
                            project.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Payment Terms:</b>
                        <div className="px-3 py-2">
                          <li>
                            The final invoice will be issued upon the conclusion
                            of the project.
                          </li>
                          <li>
                            If the project ends without a purchase order, the
                            sample cost will be invoiced at that time.
                          </li>
                        </div>
                      </p>
                      <p>
                        <b>Questions or Concerns:</b>
                        <div className="px-3 py-2">
                          <li>
                            Please contact:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              hello@packagemaven.com
                            </span>
                          </li>
                        </div>
                      </p>
                      <Row className="justify-content-md-center">
                        <Col xs={8} lg={3} className="text-center">
                          <Form.Check
                            type={'checkbox'}
                            id={'ack-checkbox'}
                            label="Agree to terms & conditions"
                            feedback="You must agree before submitting."
                            required
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Stack direction="horizontal" gap={5}>
                      <h4>Delivery address</h4>
                    </Stack>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={7}>
                        <Form.Group
                          className="mb-2"
                          controlId="address1.control"
                        >
                          <Form.Label className={styles.listItemLabel}>
                            Address 1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={address1}
                            placeholder="Street name"
                            onChange={e => setAddress1(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        <Form.Group
                          className="mb-2"
                          controlId="address2.control"
                        >
                          <Form.Label className={styles.listItemLabel}>
                            Address 2 (optional)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={address2}
                            placeholder="Suite, unit, etc."
                            onChange={e => setAddress2(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-2" controlId="city.control">
                          <Form.Label className={styles.listItemLabel}>
                            City
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-2" controlId="state.control">
                          <Form.Label className={styles.listItemLabel}>
                            State
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={state}
                            onChange={e => setState(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-2" controlId="zip.control">
                          <Form.Label className={styles.listItemLabel}>
                            Zip
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={zip}
                            onChange={e => setZip(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/*<br/>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Additional requirements</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Form.Group controlId="decorationDesignNeeds.control">
                                            <Form.Label className={styles.detailItemLabel}>Adjustment needs</Form.Label>
                                            <Form.Control as="textarea" onChange={e => setAdjustmentNeeds(e.target.value)} rows={3} />
                                        </Form.Group>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>*/}
              <Row className="mt-3 mb-2">
                <div className="text-center">
                  <Button
                    type="submit"
                    className={styles.button + ' py-2'}
                    size="sm"
                    ref={submitBtnRef}
                  >
                    <span>Submit</span>
                  </Button>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
