import styles from '../styles/styles.module.css'
import { Accordion, Col, Form, Row, Stack } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import LabeledInputField from '../components/LabeledInputField'
import LabeledInputSelect from '../components/LabeledInputSelect'
import LabeledTextarea from '../components/LabeledTextarea'
import Divider from '../components/Divider'
import * as constants from '../constants'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Import Date Picker
import { getFile } from '../services/fileStorageServices'
import LabeledDatePicker from '../components/LabeledDatePicker'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)

const useProjectForm = ({ project }) => {
  const [projectName, setProjectName] = useState(project?.name ?? '')
  const [brandName, setBrandName] = useState(project?.brand ?? '')
  const [initOrderQuantity, setInitOrderQuantity] = useState(
    project?.initOrderQuantity ?? ''
  )
  const [capacity, setCapacity] = useState(project?.capacity ?? '')
  const [acceptLargerQuantityQuotes, setAcceptLargerQuantityQuotes] = useState(
    project?.acceptLargerQuantityQuotes ?? ''
  )
  const [targetUnitCost, setTargetUnitCost] = useState(
    project?.targetUnitCost ?? ''
  )

  const [isSustainbabilityRequired, setIsSustainabilityRequired] = useState(project?.isSustainbabilityRequired ?? '')
  const [sustainabilityRequirements, setSustainabilityRequirements] = useState(project?.sustainabilityRequirements)

  const [benchmarkLink, setBenchmarkLink] = useState(
    project?.benchmarkLink ?? ''
  )
  const [baseType, setBaseType] = useState(project?.base?.type ?? '')
  const [baseMaterial, setBaseMaterial] = useState(
    project?.base?.material ?? ''
  )
  const [baseDeco, setBaseDeco] = useState(project?.base?.deco ?? '')
  const [baseFinish, setBaseFinish] = useState(project?.base?.finish ?? '')
  const [closureType, setClosureType] = useState(project?.closure?.type ?? '')
  const [closureMaterial, setClosureMaterial] = useState(
    project?.closure?.material ?? ''
  )
  const [closureDeco, setClosureDeco] = useState(project?.closure?.deco ?? '')
  const [closureFinish, setClosureFinish] = useState(
    project?.closure?.finish ?? ''
  )
  const [applicatorType, setApplicatorType] = useState(
    project?.applicator?.type ?? ''
  )
  const [isLabelNeeded, setIsLabelNeeded] = useState(
    project?.label?.isNeeded ?? ''
  )
  const [labelMaterial, setLabelMaterial] = useState(
    project?.label?.material ?? ''
  )
  const [designFiles, setDesignFiles] = useState([])
  const [decoDesignNeeds, setDecoDesignNeeds] = useState(
    project?.decoDesignNeeds ?? ''
  )
  const [designFlexibility, setDesignFlexibility] = useState(
    project?.designFlexibility ?? ''
  )
  const [otherRequirements, setOtherRequirements] = useState(
    project?.otherRequirements ?? ''
  )
  const [formulationType, setFormulationType] = useState(
    project?.formulation?.type ?? ''
  )
  const [formulationViscosity, setFormulationViscosity] = useState(
    project?.formulation?.viscosity ?? ''
  )
  const [canShareFormulation, setCanShareFormulation] = useState(
    project?.formulation?.canShare ?? ''
  )
  const [deliveryDate, setDeliveryDate] = useState(
    project?.deliveryDate ? project.deliveryDate.toDate() : null
  )
  const [receivingLocation, setReceivingLocation] = useState(
    project?.receivingLocation ?? ''
  )
  const [
    hasPartnerFreightLogisticsCompany,
    setHasPartnerFreightLogisticsCompany
  ] = useState(project?.hasPartnerFreightLogisticsCompany ?? '')
  const [preferredIncoTerm, setPreferredIncoTerm] = useState(
    project?.preferredIncoTerm ?? ''
  )
  const [supplierAuditCertification, setSupplierAuditCertification] = useState(
    project?.supplierQualifications?.auditCertification ?? ''
  )
  const [supplierSustainability, setSupplierSustainability] = useState(
    project?.supplierQualifications?.sustainability ?? ''
  )
  const [supplierService, setSupplierService] = useState(
    project?.supplierQualifications?.service ?? ''
  )
  const [supplierCapacity, setSupplierCapacity] = useState(
    project?.supplierQualifications?.capacity ?? ''
  )
  const [supplierInnovation, setSupplierInnovation] = useState(
    project?.supplierQualifications?.innovation ?? ''
  )
  const [quoteAcceptanceDueDate, setQuoteAcceptanceDueDate] = useState(
    project?.quoteAcceptanceDueDate
      ? project.quoteAcceptanceDueDate.toDate()
      : null
  )
  const [multiFormulationPackaging, setMultiFormulationPackaging] = useState(
    project?.multiFormulationPackaging ?? ''
  )
  const [hasMultipleSKUs, setHasMultipleSKUs] = useState(
    project?.hasMultipleSKUs ?? ''
  )
  const [hasContractManufacturer, setHasContractManufacturer] = useState(
    project?.hasContractManufacturer ?? ''
  )

  const isQuoteAcceptanceDueDateEditable =
    !project ||
    project.status === constants.STATUS_PROJECT_DRAFT ||
    project.status === constants.STATUS_PROJECT_INIT_ASSESSMENT

  const fileRefData = project?.fileRefData
  useEffect(() => {
    async function designFilesFetchData() {
      try {
        if (fileRefData) {
          var files = []
          for (const data of fileRefData) {
            const response = await getFile(data.blobName)
            var base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            /*const fileObj = {
                            original: `data:image/png;base64,${base64String}`,
                            originalHeight: '500',
                            thumbnail: `data:image/png;base64,${base64String}`,
                            thumnailHeight: '50',
                        }
                        files.push(fileObj);*/
            files.push(`data:image/png;base64,${base64String}`)
          }
          setDesignFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    designFilesFetchData()
  }, [])

  const form = (
    <>
      <Row className="px-3 py-3">
        <Accordion defaultActiveKey="0" className="px-0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Stack direction="horizontal" gap={5}>
                <h4>Project</h4>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="px-2">
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputField
                    controlId="projectName.control"
                    label="Project name"
                    type="text"
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputField
                    controlId="brandName.control"
                    label="Brand name"
                    type="text"
                    value={brandName}
                    onChange={e => setBrandName(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputField
                    controlId="initialOrderQuantity.control"
                    label="Initial order quantity"
                    type="number"
                    value={initOrderQuantity}
                    onChange={e => setInitOrderQuantity(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputField
                    controlId="capacity.control"
                    label="Capacity"
                    type="number"
                    value={capacity}
                    onChange={e => setCapacity(e.target.value)}
                    required
                    rightAccessory="ml"
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Divider label="(Optional)" />
              </Row>
              <Row className="px-2">
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputSelect
                    controlId="intQuotesForHigherQuantity.control"
                    label="Accepting larger order quotes"
                    value={acceptLargerQuantityQuotes}
                    options={['Yes', 'No']}
                    onChange={e =>
                      setAcceptLargerQuantityQuotes(e.target.value)
                    }
                  />
                </Col>
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputField
                    controlId="targetUnitCost.control"
                    label="Target unit cost (Unit cost = component + finish + deco)"
                    type="number"
                    value={targetUnitCost}
                    onChange={e => setTargetUnitCost(e.target.value)}
                    leftAccessory="$"
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputSelect
                    controlId="sustainability.control"
                    label="Do you have sustainability requirement?"
                    onChange={e => setIsSustainabilityRequired(e.target.value)}
                    value={isSustainbabilityRequired}
                    options={['Yes', 'No', 'Not sure']}
                  />
                </Col>
                <Col xs={12} md={6} className="py-3">
                  <LabeledInputSelect
                    controlId="sustainabilityRequirements.control"
                    label="Sustainability requirements"
                    onChange={e => setSustainabilityRequirements(e.target.value)}
                    value={sustainabilityRequirements}
                    options={[
                      'Reduce the usage of plastic',
                      'Replace EU banned materials',
                      'Use PCR (post consumer recycled) materials',
                      'Redesign to be refillable',
                      'Create repurpose pkg lifestyle',
                      'Use infinitely recyclable materials',
                      'Not sure, I would like to consult with an expert'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col xs={12} className="py-3">
                  <LabeledInputField
                    controlId="benchmarkLink.control"
                    label="Benchmark link"
                    type="text"
                    value={benchmarkLink}
                    onChange={e => setBenchmarkLink(e.target.value)}
                  />
                </Col>
                {isQuoteAcceptanceDueDateEditable && (
                  <Col xs={12} md={6} className="py-3">
                    <LabeledDatePicker
                      controlId="quoteAcceptanceDueDate.control"
                      label="Due for quote acceptance"
                      value={quoteAcceptanceDueDate}
                      onChange={setQuoteAcceptanceDueDate}
                    />
                  </Col>
                )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="px-3 py-3">
        <Accordion defaultActiveKey="0" className="px-0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Stack direction="horizontal" gap={5}>
                <h4>Design</h4>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="px-2">
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="baseType.control"
                    label="Base type"
                    value={baseType}
                    onChange={e => setBaseType(e.target.value)}
                    options={[
                      'Bottle',
                      'Jar',
                      'Tube',
                      'Pouch',
                      'Stick',
                      'Tottle',
                      'Compact',
                      'Other'
                    ]}
                    required
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="baseMaterial.control"
                    label="Base material"
                    required
                    onChange={e => setBaseMaterial(e.target.value)}
                    value={baseMaterial}
                    options={MATERIAL_OPTIONS}
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="baseFinish.control"
                    label="Base finish"
                    value={baseFinish}
                    required
                    onChange={e => setBaseFinish(e.target.value)}
                    options={[
                      'Matte Spray Coating',
                      'In-mold Matte',
                      'Glossy Spary Coating',
                      'Metalization',
                      'Vacuum Metalization',
                      'Frosting',
                      'Acid Etching',
                      'Other',
                      'None',
                      'Not sure'
                    ]}
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="baseDeco.control"
                    label="Base decoration"
                    required
                    onChange={e => setBaseDeco(e.target.value)}
                    value={baseDeco}
                    options={[
                      'Hot Stamping',
                      'Pad Printing',
                      'Digital Printing',
                      'Silk Screen',
                      'Offset Printing',
                      'Masking',
                      'Decaling',
                      'Clear Coating',
                      'Other',
                      'None',
                      'Not sure'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="closureType.control"
                    label="Clousure type"
                    required
                    onChange={e => setClosureType(e.target.value)}
                    value={closureType}
                    options={[
                      'Sprayer',
                      'Dropper',
                      'Flip Top Cap',
                      'Push Pull Cap',
                      'Disc Top Cap',
                      'Twist Open Cap',
                      'Nail Polish Cap',
                      'Threaded Cap',
                      'Plug',
                      'Tube Cap',
                      'Pump',
                      'Overcap',
                      'Sealing Disc',
                      'Other'
                    ]}
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="closureMaterial.control"
                    label="Closure material"
                    required
                    onChange={e => setClosureMaterial(e.target.value)}
                    value={closureMaterial}
                    options={MATERIAL_OPTIONS}
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="closureFinish.control"
                    label="Closure finish"
                    required
                    onChange={e => setClosureFinish(e.target.value)}
                    value={closureFinish}
                    options={[
                      'Matte Spray Coating',
                      'In-mold Matte',
                      'Glossy Spary Coating',
                      'Metalization',
                      'Vacuum Metalization',
                      'Frosting',
                      'Acid Etching',
                      'Other',
                      'None',
                      'Not sure'
                    ]}
                  />
                </Col>
                <Col lg={3} className="py-3">
                  <LabeledInputSelect
                    controlId="closureDeco.control"
                    label="Closure decoration"
                    required
                    onChange={e => setClosureDeco(e.target.value)}
                    value={closureDeco}
                    options={[
                      'Hot Stamping',
                      'Pad Printing',
                      'Digital Printing',
                      'Silk Screen',
                      'Offset Printing',
                      'Masking',
                      'Decaling',
                      'Clear Coating',
                      'Other',
                      'None',
                      'Not sure'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Divider label="(Optional)" />
              </Row>
              <Row className="px-2">
                <Col className="pt-3 pb-2">
                  <div className={styles.detailItemLabel}>Design files</div>
                  <Form.Label
                    htmlFor="designFiles.control"
                    className={styles.detailItemLabel}
                  >
                    Please upload 2D image files and PDF only.
                  </Form.Label>
                  <FilePond
                    files={designFiles}
                    instantUpload={false}
                    onupdatefiles={setDesignFiles}
                    allowMultiple={true}
                    maxFiles={20}
                    dropOnPage={true}
                    server={null}
                    name="files"
                    labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                    imagePreviewHeight={150}
                    id="designFiles.control"
                    acceptedFileTypes={[
                      'image/jpg',
                      'image/jpeg',
                      'image/png',
                      'application/pdf'
                    ]}
                  //required
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={4} className="py-3">
                  <LabeledInputSelect
                    controlId="applicatorType.control"
                    label="Applicator type"
                    onChange={e => setApplicatorType(e.target.value)}
                    value={applicatorType}
                    options={[
                      'Flock Tip',
                      'Doe Foot Tip',
                      'Needle Nozzle',
                      'Orifice Reducer',
                      'Zamach Tip',
                      'Ceramic Tip',
                      'Roller Ball',
                      'Soft Tip',
                      'Brush Tip',
                      'Sponge',
                      'Other'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={4} className="py-3">
                  <LabeledInputSelect
                    controlId="label.control"
                    label="Label"
                    onChange={e => setIsLabelNeeded(e.target.value)}
                    value={isLabelNeeded}
                    options={['Yes', 'No']}
                  />
                </Col>
                <Col lg={4} className="py-3">
                  <LabeledInputSelect
                    controlId="applicatorMaterial.control"
                    label="Label material"
                    onChange={e => setLabelMaterial(e.target.value)}
                    value={labelMaterial}
                    options={[
                      'PE',
                      'BoPP',
                      'LDPE',
                      'Paper',
                      'Mylar',
                      'Multi-layer',
                      'Other',
                      'Not sure'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col className="py-3">
                  <LabeledTextarea
                    controlId="decorationDesignNeeds.control"
                    label="Do you know what type of decoration you want? (e.g., logo/branding or text on the front panel, matte finish on the back, metallic shiny tip, etc.)"
                    value={decoDesignNeeds}
                    onChange={e => setDecoDesignNeeds(e.target.value)}
                    numberOfRows={3}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="designFlexibility.control"
                    label="Design flexibility"
                    subLabel="- Open to similar design or new tooling"
                    onChange={e => setDesignFlexibility(e.target.value)}
                    value={designFlexibility}
                    options={['Yes', 'No']}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col className="py-3">
                  <LabeledTextarea
                    controlId="otherReq.control"
                    label="Other requirements"
                    value={otherRequirements}
                    onChange={e => setOtherRequirements(e.target.value)}
                    numberOfRows={3}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="px-3 py-3">
        <Accordion defaultActiveKey="0" className="px-0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Stack direction="horizontal" gap={5}>
                <h4>Formulation</h4>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="px-2">
                <Col lg={4} className="py-3">
                  <LabeledInputSelect
                    controlId="formulationType.control"
                    label="Formulation type"
                    required
                    onChange={e => setFormulationType(e.target.value)}
                    value={formulationType}
                    options={[
                      'Gel',
                      'Cream',
                      'Balm',
                      'Emulsion',
                      'SPF (Sun care)',
                      'Oil',
                      'Loose powder',
                      'Pressed powder',
                      'Other (Chemical, hair care, etc.)',
                      'Not sure'
                    ]}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Divider label="(Optional)" />
              </Row>
              <Row className="px-2">
                <Col lg={8} className="py-3">
                  <LabeledTextarea
                    controlId="formulationViscosity.control"
                    label="What are the viscosity and pH level of the formulation?"
                    value={formulationViscosity}
                    onChange={e => setFormulationViscosity(e.target.value)}
                    numberOfRows={1}
                  />
                </Col>
                <Col lg={4} className="py-3">
                  <LabeledInputSelect
                    controlId="canShareFormulation.control"
                    label="Can you share the formulation with suppliers?"
                    onChange={e => setCanShareFormulation(e.target.value)}
                    value={canShareFormulation}
                    options={['Yes', 'No']}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="multiFormulationPackaging.control"
                    label="Will you be using this packaging for multiple formulations?"
                    onChange={e => setMultiFormulationPackaging(e.target.value)}
                    value={multiFormulationPackaging}
                    options={['Yes', 'No']}
                  />
                </Col>
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="hasMultipleSKUs.control"
                    label="Will you have more than one SKUs?"
                    onChange={e => setHasMultipleSKUs(e.target.value)}
                    value={hasMultipleSKUs}
                    options={['Yes', 'No']}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="hasContractManufacturer.control"
                    label="Do you have a contract manufacturer for formulation development and filling?"
                    onChange={e =>
                      setHasContractManufacturer(e.target.value)
                    }
                    value={hasContractManufacturer}
                    options={['Yes', 'No']}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="px-3 py-3">
        <Accordion defaultActiveKey="0" className="px-0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Stack direction="horizontal" gap={5}>
                <h4>Delivery</h4>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="px-2">
                <Col lg={6} className="py-3">
                  <LabeledDatePicker
                    controlId="deliveryDate.control"
                    label="Required delivery date"
                    required
                    value={deliveryDate}
                    onChange={setDeliveryDate}
                  />
                </Col>
                <Col lg={6} className="py-3">
                  <LabeledInputField
                    controlId="receivingLocation.control"
                    label="Receiving location (City, State)"
                    required
                    type="text"
                    onChange={e => setReceivingLocation(e.target.value)}
                    value={receivingLocation}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Divider label="(Optional)" />
              </Row>
              <Row className="px-2">
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="partnerLogisticsCompany.control"
                    label="Do you have your own freight company that you partner with?"
                    onChange={e =>
                      setHasPartnerFreightLogisticsCompany(e.target.value)
                    }
                    value={hasPartnerFreightLogisticsCompany}
                    options={['Yes', 'No']}
                  />
                </Col>
                <Col lg={6} className="py-3">
                  <LabeledInputSelect
                    controlId="incoTerm.control"
                    label="Do you have preferred INCO TERM for the freight?"
                    onChange={e => setPreferredIncoTerm(e.target.value)}
                    value={preferredIncoTerm}
                    options={[
                      'EXW (Ex Works)',
                      'FCA (Free Carrier)',
                      'FAS (Free Alongside Ship)',
                      'FOB (Free On Board) - Origin',
                      'FOB (Free On Board) - Destination',
                      'CFR (Cost and Frieght)',
                      'CIF (Cost, Insurance and Freight)',
                      'CPT (Carriage Paid To)',
                      'CIP (Carriage and Insurance Paid to)',
                      'DAT (Delivered At Terminal)',
                      'DPU (Delivered At Place)',
                      'DDP (Delivered Duty Paid)',
                      'Not sure'
                    ]}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  )

  function buildProject() {
    return {
      status: project?.status,
      brand: brandName,
      name: projectName,
      initOrderQuantity: initOrderQuantity,
      acceptLargerQuantityQuotes: acceptLargerQuantityQuotes,
      deliveryDate: deliveryDate,
      benchmarkLink: benchmarkLink,
      targetUnitCost: targetUnitCost,
      capacity: capacity,
      formulation: {
        type: formulationType,
        viscosity: formulationViscosity,
        canShare: canShareFormulation
      },
      base: {
        type: baseType,
        material: baseMaterial,
        deco: baseDeco,
        finish: baseFinish
      },
      closure: {
        type: closureType,
        material: closureMaterial,
        deco: closureDeco,
        finish: closureFinish
      },
      applicator: {
        type: applicatorType
      },
      label: {
        isNeeded: isLabelNeeded,
        material: labelMaterial
      },
      hasPartnerFreightLogisticsCompany: hasPartnerFreightLogisticsCompany,
      preferredIncoTerm: preferredIncoTerm,
      decoDesignNeeds: decoDesignNeeds,
      designFlexibility: designFlexibility,
      receivingLocation: receivingLocation,
      otherRequirements: otherRequirements,
      supplierQualifications: {
        auditCertification: supplierAuditCertification,
        sustainability: supplierSustainability,
        service: supplierService,
        capacity: supplierCapacity,
        innovation: supplierInnovation
      },
      quoteAcceptanceDueDate,
      multiFormulationPackaging,
      hasMultipleSKUs,
      hasContractManufacturer,
      isSustainbabilityRequired: isSustainbabilityRequired,
      sustainabilityRequirements: sustainabilityRequirements
    }
  }

  return { form, buildProject, designFiles }
}

const MATERIAL_OPTIONS = [
  'Metal - AL',
  'Metal - STEEL',
  'Metal - ZINC ALLOY',
  'Metal - TIN',
  'Plastic - ABS',
  'Plastic - HDPE',
  'Plastic - LDPE',
  'Plastic - MDPE',
  'Plastic - PET',
  'Plastic - PETG',
  'Plastic - PETE',
  'Plastic - PP',
  'Plastic - PCR PP',
  'Plastic - PCR PE',
  'Plastic - PCR PET',
  'Plastic - SAN',
  'Plastic - PMMA',
  'Silicone',
  'Glass',
  'Paper',
  'Compostable resin',
  'Other'
]

export default useProjectForm
