import { useEffect, useState } from 'react'
import { useStepProgress, StepProgressBar } from 'react-stepz'
import 'react-stepz/dist/index.css'

export default function ProgressBarSample(props) {
  const [currentStep, setCurrentStep] = useState(null)

  const steps = [
    {
      label: 'Submit request',
      name: 'Submit request'
    },
    {
      label: 'Request submitted',
      name: 'Request submitted'
    },
    {
      label: 'In transit',
      name: 'In transit'
    },
    {
      label: 'Delivered',
      name: 'Delivered'
    }
  ]

  const { stepForward, stepBackwards } = useStepProgress({
    steps,
    startingStep: currentStep
  })

  useEffect(() => {
    /*if (currentStep) {
            if (props?.currentStep === currentStep + 1) {
                stepForward();
            } else if (props?.currentStep === currentStep - 1) {
                stepBackwards();
            }
        }*/
    setCurrentStep(props?.currentStep)
  }, [props?.currentStep])

  return <StepProgressBar steps={steps} />
}
